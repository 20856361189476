import { parse } from 'date-fns';
import { formatDate } from 'utils/DatesUtils';

export const getRelevantEventDay = (eventDays) => {
  return eventDays.find(
    (eventDay) =>
      parse(eventDay.date + eventDay.endTime, 'yyyy-MM-ddHH:mm', new Date()) > new Date()
  );
};

export const getIsRegistrationExpired = (event) => {
  const currentDate = formatDate(new Date());
  const registrationDeadline = formatDate(event.registrationDeadline);
  const parsedCurrentDate = Date.parse(currentDate.split('.').reverse().join('/'));
  const parsedRegistrationDeadline = Date.parse(
    registrationDeadline.split('.').reverse().join('/')
  );

  return parsedRegistrationDeadline < parsedCurrentDate;
};
