import {
  BIRTHDATE_BASE_FORMAT,
  EIV_ENTRY_TYPE,
  EIV_STATUS,
  PFLEGENDE_BU,
  TENANT_ID,
  TEST_VALIDITY_DATE_BASE_FORMAT,
} from 'global/constants';
import { uniqueId } from 'lodash';

const staticInstallerData = {
  bHwkbereich: {
    id: 180,
    name: 'HWK Berlin',
    geloescht: 0,
  },
  bueroHausnummer: '12',
  bueroLand: null,
  bueroOrt: 'Werder',
  bueroOrtsteil: 'Derwitz',
  bueroPlz: '1454200',
  bueroStrasse: 'Am Berliner Ring',
  eintragungsartErgGas: null,
  eintragungsartErgStrom: null,
  email: 'efix.eon@gmail.com',
  zuInfoversand: 'Ja',
  zuAdressveroeff: 'Nein',
  zaehlermontageGas: '00',
  zaehlermontageStrom: '01',
  telefonGeschaeft: '+49 33207 532-215',
  registerLetzteAenderung: '2023-05-01',
  registergericht: 'Feld_Registergericht',
  ausweisnrFremdGas: 'Gas_ID_extern',
  ausweisnrFremdStrom: 'Energy_ID_extern',
  gewerbeanmeldungDatum: '2023-05-07',
  gewerbeanmeldungOrt: 'business_reg_Braunschweig',
  hrabnummer: 'HRA/b-Nr_test',
  hwkdatum: '2023-05-17',
  hwknummer: 'HWK nummer',
  firmeninhaber: 'Max_Firmeninhaber',
  firmenname1: 'Test Firmenname1',
  firmenname2: 'Test Firmenname2',
  firmenname3: 'Test Firmenname3',
};
const createSealsData = (tenantId) =>
  tenantId === TENANT_ID.BAG
    ? [
        {
          id: 13751,
          installateurId: 100000302464,
          fachkraftId: null,
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
          status: {
            id: 1,
            name: 'ausgegeben',
            geloescht: 0,
          },
          zangennr: '007',
          bemerkung: '',
          ausgegebenAm: '2023-09-28',
          zurueckgegebenAm: null,
          verlustAm: null,
        },
        {
          id: 13752,
          installateurId: 100000302464,
          fachkraftId: null,
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
          status: {
            id: 1,
            name: 'ausgegeben',
            geloescht: 0,
          },
          zangennr: 'M12/001',
          bemerkung: '',
          ausgegebenAm: '2023-09-28',
          zurueckgegebenAm: null,
          verlustAm: null,
        },
        {
          id: 40759,
          installateurId: 100000302464,
          fachkraftId: null,
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
          status: {
            id: 3,
            name: 'verloren',
            geloescht: 0,
          },
          zangennr: '1256',
          bemerkung: '',
          ausgegebenAm: '2024-04-06',
          zurueckgegebenAm: null,
          verlustAm: '2024-04-09',
        },
      ]
    : [];

const createContractsData = (tenantId) =>
  [TENANT_ID.AVA, TENANT_ID.BAG].includes(tenantId)
    ? [
        {
          id: 2147,
          installateurId: 100000302464,
          vertragsart: {
            id: 54,
            name: 'BAG - Nachplombierung (Neusystem ab 2021)',
            geloescht: 0,
          },
          name: '',
          telefon: '',
          mobil: '',
          mail: '',
          vertragsstatus: 'Aktiv',
          abgeschlossenAm: '2023-09-28',
          beendetAm: null,
          vertragGesperrtBis: null,
          bemerkung: '',
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
        },
        {
          id: 2148,
          installateurId: 100000302464,
          vertragsart: {
            id: 49,
            name: 'Zählersetzen Strom',
            geloescht: 0,
          },
          name: '',
          telefon: '',
          mobil: '',
          mail: '',
          vertragsstatus: 'Aktiv',
          abgeschlossenAm: '2023-09-28',
          beendetAm: null,
          vertragGesperrtBis: null,
          bemerkung: '',
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
        },
        {
          id: 2149,
          installateurId: 100000302464,
          vertragsart: {
            id: 51,
            name: 'Zählersetzen Gas',
            geloescht: 0,
          },
          name: '',
          telefon: '',
          mobil: '',
          mail: '',
          vertragsstatus: 'Aktiv',
          abgeschlossenAm: '2023-09-28',
          beendetAm: null,
          vertragGesperrtBis: null,
          bemerkung: '',
          zange: {
            id: 3,
            name: 'Netzbetreiber (Bereitstellungsgebühr)',
            geloescht: 0,
          },
        },
      ]
    : [];

const baseExpertsData = (tenantId) => {
  return {
    vorname: 'Test',
    gueltigBisGas: TEST_VALIDITY_DATE_BASE_FORMAT,
    gueltigBisStrom: TEST_VALIDITY_DATE_BASE_FORMAT,
    geburtsdatum: tenantId === TENANT_ID.BAG ? BIRTHDATE_BASE_FORMAT : '',
  };
};

const createGridnetProviders = (tenantId) => {
  switch (tenantId) {
    case TENANT_ID.BAG:
      return {
        bNetzbetreiberGas: {
          id: 1478,
          name: 'Energienetze Bayern GmbH',
          geloescht: 0,
          ort: 'Regensburg',
          plz: '93055',
          strasse: 'Grefingerstraße 22',
          netzbetreibernummer: null,
          eintragungsart: 2,
        },
        bNetzbetreiberStrom: {
          id: 33,
          name: 'Bayernwerk Netz GmbH',
          geloescht: 0,
          ort: 'Regensburg',
          plz: '93049',
          strasse: 'Heinkelstr. 1',
          netzbetreibernummer: 10681,
          eintragungsart: 1,
        },
      };
    case TENANT_ID.SHNG:
      return {
        bNetzbetreiberGas: {
          id: 1568,
          name: 'HanseGas GmbH',
          geloescht: 0,
          ort: 'Quickborn',
          plz: '25450',
          strasse: 'Schleswag-HeinGas-Platz 1',
          netzbetreibernummer: 700339,
          eintragungsart: 1,
        },
        bNetzbetreiberStrom: {
          id: 736,
          name: 'SWKiel Netz GmbH',
          geloescht: 0,
          ort: 'Kiel',
          plz: '24116',
          strasse: 'Knooper Weg 75',
          netzbetreibernummer: 339,
          eintragungsart: 2,
        },
      };
    case TENANT_ID.AVA:
      return {
        bNetzbetreiberGas: {
          id: 32,
          name: 'Avacon Netz GmbH',
          geloescht: 0,
          ort: 'Helmstedt',
          plz: '38350',
          strasse: 'Schillerstraße 3',
          netzbetreibernummer: 7137,
          eintragungsart: 1,
        },
        bNetzbetreiberStrom: {
          id: 936,
          name: 'Stadtwerke Überlingen GmbH',
          geloescht: 0,
          ort: 'Überlingen',
          plz: '88662',
          strasse: 'Kurt-Wilde-Str. 10',
          netzbetreibernummer: 643,
          eintragungsart: 2,
        },
      };
    case TENANT_ID.EDI:
      return {
        bNetzbetreiberGas: {
          id: 35,
          name: 'E.DIS Netz GmbH',
          geloescht: 0,
          ort: 'Fürstenwalde',
          plz: '15517',
          strasse: 'Langewahler Str. 60',
          netzbetreibernummer: 1000,
          eintragungsart: 1,
        },
        bNetzbetreiberStrom: {
          id: 132,
          name: 'Netzgesellschaft Potsdam GmbH (NGP)',
          geloescht: 0,
          ort: 'Potsdam',
          plz: '14480',
          strasse: 'Steinstraße 104-106',
          netzbetreibernummer: 792,
          eintragungsart: 2,
        },
      };
  }
};

const createCompanyTypes = (partsEnergy, partsGas) => ({
  bBetriebsartGas: {
    id: 1,
    name: partsGas[2] === 'H' ? 'Hilfsbetrieb' : 'Hauptbetrieb',
    geloescht: 0,
    pflegendeBu: '0',
  },
  bBetriebsartStrom: {
    id: 1,
    name: partsEnergy[2] === 'H' ? 'Hilfsbetrieb' : 'Hauptbetrieb',
    geloescht: 0,
    pflegendeBu: '0',
  },
});

export const createBaseData = (tenantId, partsEnergy, partsGas) => {
  const isEnergyActive = partsEnergy.length > 0;
  const isGasActive = partsGas.length > 0;

  const installerBranches = {
    //branch active
    statusGas: isGasActive ? EIV_STATUS.ACTIVE : EIV_STATUS.NOT_SET,
    statusStrom: isEnergyActive ? EIV_STATUS.ACTIVE : EIV_STATUS.NOT_SET,
    //entry type
    eintragungsartStrom: partsEnergy[0] === 'Main' ? EIV_ENTRY_TYPE.MAIN : EIV_ENTRY_TYPE.GUEST,
    eintragungsartGas: partsGas[0] === 'Main' ? EIV_ENTRY_TYPE.MAIN : EIV_ENTRY_TYPE.GUEST,
    //expiry dates
    auswStromGueltig: TEST_VALIDITY_DATE_BASE_FORMAT,
    auswGasGueltig: TEST_VALIDITY_DATE_BASE_FORMAT,
  };

  const avaGuestPostal =
    tenantId === TENANT_ID.AVA && [partsEnergy[0], partsGas[0]].includes('Main')
      ? {}
      : { avaGuestPostal: '38350' };

  return {
    id: uniqueId(),
    ...installerBranches,
    ...avaGuestPostal,
    ...createGridnetProviders(tenantId),
    ...createCompanyTypes(partsEnergy, partsGas),
    ...staticInstallerData,
    pflegendeBu: Object.values(PFLEGENDE_BU[tenantId])[0],
  };
};

export const createExperts = (tenantId, partsEnergy, partsGas) => {
  const experts = [];

  const isEnergyActive = partsEnergy.length > 0;
  const isGasActive = partsGas.length > 0;

  const expertGas = {
    ...baseExpertsData(tenantId),
    nachname: 'Gas',
    statusStrom: EIV_STATUS.NOT_SET,
    statusGas: EIV_STATUS.ACTIVE,
    id: uniqueId(),
  };
  const expertEnergy = {
    ...baseExpertsData(tenantId),
    nachname: 'Strom',
    statusStrom: EIV_STATUS.ACTIVE,
    statusGas: EIV_STATUS.NOT_SET,
    id: uniqueId(),
  };
  const expertMixed = {
    ...baseExpertsData(tenantId),
    nachname: 'Gas-Strom',
    statusStrom: EIV_STATUS.ACTIVE,
    statusGas: EIV_STATUS.ACTIVE,
    id: uniqueId(),
  };

  if (isGasActive && isEnergyActive) {
    experts.push(expertMixed);
  }
  if (isEnergyActive) {
    experts.push(expertEnergy);
  }
  if (isGasActive) {
    experts.push(expertGas);
  }
  return experts;
};

export const createInstallerData = (tenantId, branchNameEnergy, branchNameGas) => {
  const partsEnergy = branchNameEnergy.match(/[A-Z][a-z]*/g) || [];
  const partsGas = branchNameGas.match(/[A-Z][a-z]*/g) || [];

  return {
    ...createBaseData(tenantId, partsEnergy, partsGas),
    experts: createExperts(tenantId, partsEnergy, partsGas),
    seals: createSealsData(tenantId),
    contracts: createContractsData(tenantId),
  };
};
