import * as Yup from 'yup';

import { YDate, YZip } from 'utils/YupUtils';
import { getTenantId } from 'utils/tenantUtil';
import { isRegisteringInstallerGuestOnly } from 'utils/InstallerUtils';
import { isRegisteringBranchGuest } from 'utils/InstallerUtils';
import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS, BRANCH_TYPE, TENANT_ID } from 'global/constants';
import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';

export const YNEStepFormPageSchema = (branchSelectionValues) =>
  Yup.object().shape({
    companyName1: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyName2: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    companyName3: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),

    companyOwner: Yup.lazy(() => {
      if (TENANT_ID.BAG === getTenantId()) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    companyStreet: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyHouseNumber: Yup.string()
      .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_HAUSNUMMER, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyPostal: Yup.string()
      .min(5, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_PLZ, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyCity: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_ORT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyRegion: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_ORTSTEIL, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    phone: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.TELEFON_GESCHAEFT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    mobile: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.TELEFON_MOBIL, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    gridProviderEnergy: Yup.lazy(() => {
      if (isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    foreignCompanyIdEnergy: Yup.lazy(() => {
      if (isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    gridProviderGas: Yup.lazy(() => {
      if (isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    foreignCompanyIdGas: Yup.lazy(() => {
      if (isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    experts: Yup.array().min(1, VALIDATION_CONSTANTS.MANDATORY),
    craftsChamberArea: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        if (isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),

    craftsChamberNumber: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        if (isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    craftsChamberDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        if (isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)) {
          return YDate;
        }
        return YDate.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    businessRegistrationLocation: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        if (isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),

    businessRegistrationDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        if (isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)) {
          return YDate;
        }
        return YDate.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    commercialRegisterNumber: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    commercialRegisterDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        return YDate;
      }
      return Yup.string().nullable().optional();
    }),
    gasAgreement: Yup.bool().when([], {
      is: () => {
        return branchSelectionValues.isGasActive === true;
      },
      then: (s) => s.oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
    energyAgreement: Yup.bool().when([], {
      is: () => {
        return branchSelectionValues.isEnergyActive === true;
      },
      then: (s) => s.oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
    avaGuestPostal: Yup.lazy(() => {
      if (
        TENANT_ID.AVA === getTenantId() &&
        isRegisteringInstallerGuestOnly(branchSelectionValues)
      ) {
        return YZip.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
  });
