import React from 'react';
import './Tags.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Tags = ({ tags, selectedTags, className, handleTagSelection, inverted }) => {
  const getIsTagActive = (tag) => {
    if (!selectedTags || !selectedTags.length) return false;
    const isActive = selectedTags.includes(tag);
    return isActive;
  };

  const tagsBoxClasses = classnames('tags-box', {
    [`${className}`]: !!className,
  });

  const handleClick = (tag) => {
    if (!handleTagSelection) return;
    handleTagSelection(tag);
  };

  return (
    <div className={tagsBoxClasses}>
      {tags.map((tag, key) => {
        const tagClasses = classnames('tag', {
          [`tag--active`]: getIsTagActive(tag),
          [`tag--inverted`]: inverted,
          [`tag--selectable`]: !!handleTagSelection,
          [`${className}__tag--active`]: getIsTagActive(tag),
          [`${className}__tag`]: !!className,
        });

        return (
          <p onClick={() => handleClick(tag)} className={tagClasses} key={key}>
            {tag}
          </p>
        );
      })}
    </div>
  );
};

Tags.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleTagSelection: PropTypes.func,
  inverted: PropTypes.bool,
};

Tags.defaultProps = {
  inverted: false,
};

export default Tags;
