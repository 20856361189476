import React, { useEffect, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { DownloadInstallerIDItem } from 'components/DownloadInstallerIDItem/DownloadInstallerIDItem';

import './DocumentDownload.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { getTenantId, GRIDNET_PROVIDER } from 'utils/tenantUtil';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import { useCurrentExperts } from 'hooks/UseCurrentExperts';
import { FFormDebug } from 'utils/FormUtils';
import { getDEBPath } from 'utils/UrlUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { BRANCH_TYPE, TENANT_ID } from 'global/constants';
import { isDocumentDownloadElegible, prepareDocumentDownloadData } from 'utils/DocumentsUtils';
import { useCurrentContracts } from 'hooks/UseCurrentContracts';
import { isUndefined } from 'lodash';

const DOCUMENTS = {
  [TENANT_ID.EDI]: [
    {
      id: GRIDNET_PROVIDER.EDI.EDI,
      name: 'Installateurausweis Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'EDI_MAIN_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.EDI.EDI,
      name: 'Installateurausweis Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'EDI_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.EDI.EDI,
      name: 'Eintragungsbestätigung Gast Gas',
      type: BRANCH_TYPE.GAS,
      guest: true,
      templateId: 'EDI_GUEST_GAS',
    },
    {
      id: GRIDNET_PROVIDER.EDI.EDI,
      name: 'Eintragungsbestätigung Gast Strom',
      type: BRANCH_TYPE.ENERGY,
      guest: true,
      templateId: 'EDI_GUEST_ENERGY',
    },
  ],
  [TENANT_ID.SHNG]: [
    {
      id: GRIDNET_PROVIDER.SHNG,
      name: 'Information Gasteintragung Gas',
      guest: true,
      type: BRANCH_TYPE.GAS,
      templateId: 'SHNG_GUEST_GAS',
    },
    {
      id: GRIDNET_PROVIDER.SHNG,
      guest: true,
      name: 'Information Gasteintragung Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'SHNG_GUEST_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.SHNG,
      name: 'SH-Netz Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'SHNG_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.SHNG,
      name: 'SH-Netz Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'SHNG_MAIN_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.NNNG,
      name: 'NordNetz Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'NNNG_MAIN_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.NNNG,
      name: 'NordNetz Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'NNNG_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.HANSG,
      name: 'HanseGas Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'HANSG_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.SHNG.EENG,
      name: 'ElbEnergie Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'EENG_MAIN_GAS',
    },
  ],
  [TENANT_ID.AVA]: [
    {
      id: GRIDNET_PROVIDER.AVA.AVA,
      name: 'Installateurausweis Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'AVA_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.AVA.AVA,
      name: 'Installateurausweis Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'AVA_MAIN_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.AVA.AVA,
      name: 'Eintragungsbestätigung Gast Gas',
      type: BRANCH_TYPE.GAS,
      guest: true,
      templateId: 'AVA_GUEST_GAS',
    },
    {
      id: GRIDNET_PROVIDER.AVA.AVA,
      name: 'Eintragungsbestätigung Gast Strom',
      type: BRANCH_TYPE.ENERGY,
      guest: true,
      templateId: 'AVA_GUEST_ENERGY',
    },
  ],
  [TENANT_ID.BAG]: [
    {
      id: GRIDNET_PROVIDER.BAG.BAG,
      name: 'Installateurausweis Gas',
      type: BRANCH_TYPE.GAS,
      templateId: 'BAG_MAIN_GAS',
    },
    {
      id: GRIDNET_PROVIDER.BAG.BAG,
      name: 'Installateurausweis Strom',
      type: BRANCH_TYPE.ENERGY,
      templateId: 'BAG_MAIN_ENERGY',
    },
    {
      id: GRIDNET_PROVIDER.BAG.BAG,
      name: 'Eintragungsbestätigung Gast Gas',
      type: BRANCH_TYPE.GAS,
      guest: true,
      templateId: 'BAG_GUEST_GAS',
    },
    {
      id: GRIDNET_PROVIDER.BAG.BAG,
      name: 'Eintragungsbestätigung Gast Strom',
      type: BRANCH_TYPE.ENERGY,
      guest: true,
      templateId: 'BAG_GUEST_ENERGY',
    },
  ],
};

export const DocumentDownload = () => {
  const CURRENT_DOCUMENTS = DOCUMENTS[getTenantId()];
  const [parsedData, setParsedData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { installer, isLoading: isInstallerLoading } = useCurrentInstaller();
  const { experts, isLoading: isExpertsLoading } = useCurrentExperts();
  const { contracts, isLoading: isContractsLoading } = useCurrentContracts();

  useEffect(() => {
    (async () => {
      if (
        !isInstallerLoading &&
        installer &&
        !isExpertsLoading &&
        !isUndefined(experts) &&
        !isContractsLoading &&
        !isUndefined(contracts)
      ) {
        try {
          const data = prepareDocumentDownloadData(installer, experts, contracts);
          setParsedData(data);
          setIsLoading(false);
        } catch (e) {
          console.log('DocumentDownload data error', e);
        }
      }
    })();
  }, [installer, experts, contracts, isInstallerLoading, isExpertsLoading, isContractsLoading]);

  const renderDownloadItems = (selectedExpert) => {
    const eligibleDocs = CURRENT_DOCUMENTS.filter((documentInfomation) =>
      isDocumentDownloadElegible(documentInfomation, parsedData, selectedExpert)
    );
    if (eligibleDocs.length === 0) {
      return (
        <div className="no-file-info">
          Ihr Eintrag ist derzeit inaktiv. Zur Verlängerung der Fachkraft senden Sie uns bitte Ihre
          aktuellen Daten über <a href={getDEBPath()}>{'unser Formular'}</a>
        </div>
      );
    }
    return eligibleDocs.map((doc) => {
      return (
        <DownloadInstallerIDItem
          expert={selectedExpert}
          key={doc.name}
          documentInformation={doc}
          parsedData={parsedData}
        />
      );
    });
  };
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="DocumentDownload" />;
  }
  return (
    <div className="document-download">
      {!isLoading && parsedData ? (
        parsedData.isEnergyActive || parsedData.isGasActive ? (
          <>
            {parsedData.installers
              .sort((firstExpert, secondExpert) => {
                return firstExpert.lastname.localeCompare(secondExpert.lastname);
              })
              .map((selectedExpert, i) => {
                return (
                  <div className="document-download__download-wrapper" key={i}>
                    <SubTitle
                      notFramed
                      text={selectedExpert.firstname + ' ' + selectedExpert.lastname}
                    />
                    <div className="document-download__download-section-wrapper">
                      {renderDownloadItems(selectedExpert)}
                    </div>
                  </div>
                );
              })}
            <FFormDebug values={parsedData} />
          </>
        ) : (
          <div>
            Ihre Ausweisgültigkeit ist abgelaufen, bitte verlängern Sie ihre Eintragung:{' '}
            <a href={getDEBPath()}>{'Zur Verlängerung'}</a>
          </div>
        )
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

DocumentDownload.displayName = 'DocumentDownload';

DocumentDownload.propTypes = {};

DocumentDownload.defaultProps = {};

MapTo(['e-fix/components/content/documentdownload'])(DocumentDownload);
