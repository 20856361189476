/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import './CardCollectionMyData.sass';
import CardComponent from '../../components/CardComponent';
import { Link } from 'react-router-dom';
import { parseCardsMyData } from '../../utils/CardDataUtils';
import { MapTo } from '@adobe/aem-react-editable-components';
import { shouldShowMyDataCard } from 'utils/DataUtils';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Utils } from '@adobe/aem-react-editable-components';
import { isBUofCurrentTenant } from 'utils/tenantUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { PFLEGENDE_BU_ID_TO_NAME } from 'global/constants';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import { useAuth } from 'context/Auth';

const CardCollectionMyData = (props) => {
  const isEditor = Utils.isInEditor();

  const { isCIAM } = useAuth();
  const parsedCards = useMemo(() => parseCardsMyData(props.links), [props]);
  const { installer, isLoading } = useCurrentInstaller();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="CardCollectionMyData" />;
  }
  return (
    <div className="card-collection-my-data">
      <div className="mdl-grid card-collection-my-data__card-grid">
        {!isLoading && installer ? (
          isBUofCurrentTenant(installer.pflegendeBu) ? (
            parsedCards.map((card, index) => {
              if (shouldShowMyDataCard(card.id, installer)) {
                return (
                  <Link key={index} to={card.linkedPage}>
                    <CardComponent
                      cardClass="search-pre-selection-card mdl-cell mdl-cell--4-col"
                      card={card}
                    />
                  </Link>
                );
              }
            })
          ) : (
            <SubTitle
              className="card-collection-my-data__sub-title-wrapper"
              text={`Nutzen Sie das Portal Ihres pflegenden Netzbetreibers ${
                PFLEGENDE_BU_ID_TO_NAME[installer.pflegendeBu]
              }. Wählen Sie diesen über Portalwechsel aus.`}
            />
          )
        ) : (
          <SubTitle
            className="card-collection-my-data__sub-title-wrapper"
            text={
              isCIAM === false
                ? 'Nutzen Sie das Installateurs-Login um Ihre Daten zu verwalten'
                : 'Lädt...'
            }
          />
        )}
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/cardcollectionMyData')(CardCollectionMyData);
