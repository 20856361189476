import { createInstallerData } from 'components/DEBForm/Utils/DebTestAccountSetup';
import { STORAGE_KEY } from 'global/constants';
import { TESTING_ACCOUNT_ITEM_MAP } from 'utils/FormUtils';
import { isLocal } from 'utils/StageUtils';
import { getTenantId } from 'utils/tenantUtil';

export const DEB_TEST_SUITES = (tenantId) => ({
  MainEnergyNonH: {
    installerData: createInstallerData(tenantId, 'MainEnergyNonH', ''),
    id: 1,
  },
  MainEnergyH: {
    installerData: createInstallerData(tenantId, 'MainEnergyH', ''),
    id: 2,
  },
  MainGasNonH: {
    installerData: createInstallerData(tenantId, '', 'MainGasNonH'),
    id: 3,
  },
  MainGasH: {
    installerData: createInstallerData(tenantId, '', 'MainGasH'),
    id: 4,
  },
  MainEnergyNonHMainGasNonH: {
    installerData: createInstallerData(tenantId, 'MainEnergyNonH', 'MainGasNonH'),
    id: 5,
  },
  MainEnergyHMainGasNonH: {
    installerData: createInstallerData(tenantId, 'MainEnergyH', 'MainGasNonH'),
    id: 6,
  },
  MainEnergyNonHMainGasH: {
    installerData: createInstallerData(tenantId, 'MainEnergyNonH', 'MainGasH'),
    id: 7,
  },
  MainEnergyHMainGasH: {
    installerData: createInstallerData(tenantId, 'MainEnergyH', 'MainGasH'),
    id: 8,
  },
  MainEnergyNonHGuestGasNonH: {
    installerData: createInstallerData(tenantId, 'MainEnergyNonH', 'GuestGasNonH'),
    id: 9,
  },
  MainEnergyHGuestGasNonH: {
    installerData: createInstallerData(tenantId, 'MainEnergyH', 'GuestGasNonH'),
    id: 10,
  },
  MainEnergyNonHGuestGasH: {
    installerData: createInstallerData(tenantId, 'MainEnergyNonH', 'GuestGasH'),
    id: 11,
  },
  MainEnergyHGuestGasH: {
    installerData: createInstallerData(tenantId, 'MainEnergyH', 'GuestGasH'),
    id: 12,
  },
  GuestEnergyNonHMainGasNonH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyNonH', 'MainGasNonH'),
    id: 13,
  },
  GuestEnergyNonHMainGasH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyNonH', 'MainGasH'),
    id: 14,
  },
  GuestEnergyHMainGasNonH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyH', 'MainGasNonH'),
    id: 15,
  },
  GuestEnergyHMainGasH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyH', 'MainGasH'),
    id: 16,
  },
  GuestEnergyNonH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyNonH', ''),
    id: 17,
  },

  GuestGasNonH: {
    installerData: createInstallerData(tenantId, '', 'GuestGasNonH'),
    id: 18,
  },
  GuestEnergyH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyH', ''),
    id: 19,
  },
  GuestGasH: {
    installerData: createInstallerData(tenantId, '', 'GuestGasH'),
    id: 20,
  },
  GuestEnergyNonHGuestGasNonH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyNonH', 'GuestGasNonH'),
    id: 21,
  },
  GuestEnergyHGuestGasNonH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyH', 'GuestGasNonH'),
    id: 22,
  },
  GuestEnergyNonHGuestGasH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyNonH', 'GuestGasH'),
    id: 23,
  },
  GuestEnergyHGuestGasH: {
    installerData: createInstallerData(tenantId, 'GuestEnergyH', 'GuestGasH'),
    id: 24,
  },
});
const shouldFillDebTestData = localStorage.getItem(STORAGE_KEY.FILL_DEB_FORM) === 'true';
const selectedTestSuiteValueLocalStorage = localStorage.getItem(
  STORAGE_KEY.DEB_SELECTED_TEST_SUITE
);
const selectedTestSuiteValue =
  selectedTestSuiteValueLocalStorage || TESTING_ACCOUNT_ITEM_MAP[0]['value'];

if (!selectedTestSuiteValueLocalStorage) {
  localStorage.setItem(STORAGE_KEY.DEB_SELECTED_TEST_SUITE, selectedTestSuiteValue);
}

const DEB_CURRENT_TEST_SUITE = DEB_TEST_SUITES(getTenantId())[selectedTestSuiteValue];
if (isLocal()) {
  console.log(
    'Current DEB Testsuite:',
    getTenantId(),
    selectedTestSuiteValue,
    DEB_CURRENT_TEST_SUITE
  );
}
export { DEB_CURRENT_TEST_SUITE, shouldFillDebTestData, selectedTestSuiteValue };
