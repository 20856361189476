import { EIV_ENTRY_TYPE } from 'global/constants';

export const getBranchSelectionData = (branchNameEnergy, branchNameGas) => {
  const partsEnergy = branchNameEnergy.match(/[A-Z][a-z]*/g) || [];
  const partsGas = branchNameGas.match(/[A-Z][a-z]*/g) || [];
  const isEnergyActive = partsEnergy.length > 0;
  const isGasActive = partsGas.length > 0;
  return {
    isEnergyActive,
    isGasActive,
    companyTypeEnergy: isEnergyActive
      ? partsEnergy[2] === 'H'
        ? 'Hilfsbetrieb'
        : 'Hauptbetrieb'
      : '',
    companyTypeGas: isGasActive ? (partsGas[2] === 'H' ? 'Hilfsbetrieb' : 'Hauptbetrieb') : '',
    branchTypeEnergy: isEnergyActive
      ? partsEnergy[0] === 'Main'
        ? EIV_ENTRY_TYPE.MAIN
        : EIV_ENTRY_TYPE.GUEST
      : '',
    branchTypeGas: isGasActive
      ? partsGas[0] === 'Main'
        ? EIV_ENTRY_TYPE.MAIN
        : EIV_ENTRY_TYPE.GUEST
      : '',
  };
};
