import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

const TooltipIconButton = forwardRef(function tooltipIconButton(props, ref) {
  const { children } = props;
  return (
    <IconButton
      style={{ padding: 0, alignItems: 'center', alignContent: 'center' }}
      //className doesn't work
      ref={ref}
      {...props}>
      {children}
    </IconButton>
  );
});

TooltipIconButton.propTypes = {
  children: PropTypes.node.isRequired,
  ref: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export { TooltipIconButton };
