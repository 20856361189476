import gql from 'graphql-tag';

export const GET_CONTACTS = gql`
  query contacts($zip: Zip!, $city: String!, $district: String!, $tile: Tile!) {
    contacts(zip: $zip, city: $city, district: $district, tile: $tile) {
      email
      phoneNumberCall
      phoneNumberDisplay
      type
      marker
      location {
        salesOffice
      }
    }
  }
`;

export const GET_INCIDENTS = gql`
  {
    incidents {
      zip
      city
      district
      street
      dateCreated
    }
  }
`;

export const GET_EVENTS_OVERVIEW_DATA_QUERY = gql`
  query {
    events(top: 5) {
      id
      startDate
      endDate
      registrationDeadline
      tags
      creationDate
      title
      path
      allParticipants {
        companyName
        participants {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_ARTICLES_OVERVIEW_DATA_QUERY = gql`
  query {
    articles(top: 5) {
      id
      creationDate
      tags
      title
      description
      shortDescription
      path
    }
  }
`;

export const getAllEventsQuery = gql`
  query {
    events {
      id
      path
      startDate
      endDate
      title
      description
      location {
        description
        street
        streetNumber
        zip
        city
      }
      registrationDeadline
      tags
      attachmentUrl
      disableMaxParticipation
      maxParticipants
      maxParticipantsPerCompany
      registration {
        companyName
        eventParticipantsNumber
        participants {
          id
          firstName
          lastName
          eMail
          userId
        }
      }
      attachmentUrl
      eventDays {
        date
        startTime
        endTime
      }
      allParticipants {
        companyName
        participants {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query ($id: Int!) {
    event(id: $id) {
      id
      path
      creationDate
      startDate
      endDate
      title
      description
      chargingAmount
      chargingNote
      location {
        description
        street
        streetNumber
        zip
        city
      }
      registrationDeadline
      tags
      attachmentUrl
      meetingLink
      disableMaxParticipation
      maxParticipants
      maxParticipantsPerCompany
      registration {
        companyName
        eventParticipantsNumber
        participants {
          id
          firstName
          lastName
          eMail
          userId
        }
      }
      attachmentUrl
      eventDays {
        date
        startTime
        endTime
      }
      allParticipants {
        companyName
        installerId
        participants {
          id
          firstName
          lastName
          eMail
          userId
        }
      }
    }
  }
`;

export const GET_ARTICLES_QUERY = gql`
  query (
    $searchCriteria: ArticlesSearchCriteria
    $action: PaginationAction!
    $cursor: LocalDateTime
    $limit: Int!
    $page: Int!
  ) {
    articles(
      searchCriteria: $searchCriteria
      pagination: { action: $action, cursor: $cursor, limit: $limit, page: $page }
    ) {
      likes
      id
      creationDate
      tags
      title
      description
      shortDescription
      path
      enableFeedback
    }
  }
`;

export const GET_TOTAL_ARTICLE_PAGES_QUERY = gql`
  query ($searchCriteria: ArticlesSearchCriteria, $limit: Int!) {
    articlesPagesNumber(searchCriteria: $searchCriteria, limit: $limit)
  }
`;

export const GET_EVENT_QUERY = gql`
  query (
    $searchCriteria: EventsSearchCriteria
    $action: PaginationAction!
    $cursor: LocalDateTime
    $limit: Int!
  ) {
    events(
      searchCriteria: $searchCriteria
      pagination: { action: $action, cursor: $cursor, limit: $limit }
    ) {
      creationDate
      id
      path
      startDate
      endDate
      title
      description
      location {
        description
        street
        streetNumber
        zip
        city
      }
      tags
      attachmentUrl
      meetingLink
      disableMaxParticipation
      maxParticipants
      maxParticipantsPerCompany
      registrationDeadline
      registration {
        companyName
        participants {
          id
        }
        eventParticipantsNumber
      }
      attachmentUrl
      eventDays {
        date
        startTime
        endTime
      }
      allParticipants {
        companyName
        participants {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PAST_EVENTS_QUERY = gql`
  query {
    pastEvents {
      creationDate
      id
      path
      startDate
      endDate
      title
      description
      location {
        description
        street
        streetNumber
        zip
        city
      }
      tags
      attachmentUrl
      meetingLink
      disableMaxParticipation
      maxParticipants
      maxParticipantsPerCompany
      registrationDeadline
      registration {
        companyName
        participants {
          id
        }
        eventParticipantsNumber
      }
      attachmentUrl
      eventDays {
        date
        startTime
        endTime
      }
      allParticipants {
        companyName
        participants {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TOTAL_EVENT_PAGES_QUERY = gql`
  query ($searchCriteria: EventsSearchCriteria, $limit: Int!) {
    eventsPagesNumber(searchCriteria: $searchCriteria, limit: $limit)
  }
`;

export const GET_USER_EVENTS_QUERY = gql`
  query {
    myRegisteredEvents {
      id
      path
      startDate
      endDate
      title
      meetingLink
      deleted
      eventDays {
        date
        startTime
        endTime
      }
      registration {
        participants {
          id
        }
      }
    }
  }
`;

export const CONTACT_EXPERT_QUERY = gql`
  query ($mailInput: MailInput!) {
    contactExpert(mail: $mailInput) {
      status
    }
  }
`;

export const USER_STATUS_QUERY = gql`
  query {
    userStatus {
      notifications {
        articles
        events
      }
      subscription
      role
      iamType
      id
      tempEmail
      latestDebFormRequestDate
    }
  }
`;

export const SUBSCRIPTION_STATUS_QUERY = gql`
  query {
    userStatus {
      subscription
    }
  }
`;

export const GET_LOCATIONS_QUERY = gql`
  query locations($zip: Zip!, $tile: Tile!) {
    locations(zip: $zip, tile: $tile) {
      city
      district
    }
  }
`;

export const GET_INSTALLERID_QUERY = gql`
  query installerId($installerIdInput: InstallerIdInput!) {
    installerId(installerIdInput: $installerIdInput) {
      base64
    }
  }
`;

export const GET_INSTALLER_QUERY = gql`
  query getInstaller {
    getInstaller {
      id
      firmenname1
      firmenname2
      firmenname3
      firmeninhaber
      email
      telefonGeschaeft
      telefonMobil
      fax
      bueroStrasse
      bueroHausnummer
      bueroPlz
      bueroOrt
      bueroOrtsteil
      bueroLand
      zuAdressveroeff
      zuInfoversand
      eintragungsartStrom
      eintragungsartGas
      bBetriebsartStrom {
        name
      }
      bBetriebsartGas {
        name
      }
      statusStrom
      statusGas
      auswStromGueltig
      auswGasGueltig
      auswStromDatum
      auswGasDatum
      eintragungsartErgStrom
      eintragungsartErgGas
      gewerbeanmeldungOrt
      gewerbeanmeldungDatum
      hrabnummer
      hwknummer
      hwkdatum
      pflegendeBu
      registergericht
      registerLetzteAenderung
      geloeschtAm
      geloeschtGasAm
      geloeschtWasserAm
      ausweisnrFremdStrom
      ausweisnrFremdGas
      eintragungsartErgGas
      eintragungsartErgStrom
      bNetzbetreiberGas {
        name
      }
      bNetzbetreiberStrom {
        name
      }
      bHwkbereich {
        name
      }
    }
  }
`;

export const GET_INSTALLER_EXPERTS_QUERY = gql`
  query getInstallerExperts {
    getInstallerExperts {
      id
      geburtsdatum
      gueltigBisGas
      gueltigBisStrom
      gueltigBisWasser
      nachname
      statusGas
      statusStrom
      statusWasser
      vorname
    }
  }
`;

// blocked because of gekundigtzum (beendetAm)
export const GET_INSTALLER_CONTRACTS_QUERY = gql`
  query getInstallerContracts {
    getInstallerContracts {
      id
      vertragsart {
        name
        id
      }
      vertragsstatus
      abgeschlossenAm
      beendetAm
    }
  }
`;

export const GET_INSTALLER_SEALS_QUERY = gql`
  query getInstallerSeals {
    getInstallerSeals {
      id
      zangennr
      status {
        name
      }
      ausgegebenAm
      zurueckgegebenAm
      verlustAm
    }
  }
`;

// PUBLIC queries which need no authentication config (see Apollo.jsx)

export const PUBLIC_GET_CIAM_STATUS_BY_EMAIL = gql`
  query GetCiamStatusByEmail($email: String!) {
    getCiamStatusByEmail(email: $email) {
      ciamStatus
      statusStrom
      statusGas
      status
    }
  }
`;
