import * as Yup from 'yup';
import { YExpert } from './YExpert';
// import { YContract } from './YContract';
import { YDate, YZip } from 'utils/YupUtils';
import { YSeals } from './YSeals';
import { BRANCH_TYPE, EIV_CHAR_LENGTH, TENANT_ID, VALIDATION_CONSTANTS } from 'global/constants';
import { getTenantId } from 'utils/tenantUtil';
import {
  doesInstallerHaveBranch,
  isBranchGuest,
  isRegisteredInstallerGuestOnly,
} from 'utils/InstallerUtils';
import { isRegisteredInstallerOnlyHilfsbetrieb } from 'components/DEBForm/Utils/DebFormSectionUtil';

export const YDebFormSchema = (installerStatusSummary, doesNeedAvaGuestPostal) =>
  Yup.object().shape({
    companyName1: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyName2: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    companyName3: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    companyOwner: Yup.lazy(() => {
      if (TENANT_ID.BAG === getTenantId()) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    companyStreet: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyHouseNumber: Yup.string()
      .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_HAUSNUMMER, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyPostal: Yup.string()
      .min(5, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_PLZ, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyCity: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_ORT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyRegion: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_ORTSTEIL, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    mobile: Yup.lazy((value) => {
      if (value !== undefined && value !== '') {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.TELEFON_MOBIL, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    phone: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.TELEFON_GESCHAEFT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),

    craftsChamberArea: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        if (isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),

    craftsChamberNumber: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        if (isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    craftsChamberDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        if (isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary)) {
          return YDate;
        }
        return YDate.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),

    businessRegistrationLocation: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        if (isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary)) {
          return Yup.string()
            .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
            .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
        }
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
          .required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    businessRegistrationDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        if (isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary)) {
          return YDate;
        }
        return YDate.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),
    commercialRegisterDate: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary) //at least one main
      ) {
        return YDate;
      }
      return Yup.string().nullable().optional();
    }),
    commercialRegisterNumber: Yup.lazy(() => {
      if (
        TENANT_ID.BAG === getTenantId() &&
        !isRegisteredInstallerGuestOnly(installerStatusSummary)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG);
      }

      return Yup.string().nullable().optional();
    }),
    seals: Yup.array().of(YSeals),
    doesEnergyBranchExist: Yup.bool().required(),
    doesGasBranchExist: Yup.bool().required(),
    doesNeedAvaGuestPostal: Yup.bool().required(),
    avaGuestPostal: Yup.lazy(() => {
      if (doesNeedAvaGuestPostal) {
        return YZip.required(VALIDATION_CONSTANTS.MANDATORY);
      }
      return Yup.string().nullable().optional();
    }),

    gridProviderEnergy: Yup.lazy(() => {
      if (
        doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
        isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG);
      }
      return Yup.string().nullable().optional();
    }),
    foreignCompanyIdEnergy: Yup.lazy(() => {
      if (
        doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
        isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    gridProviderGas: Yup.lazy(() => {
      if (
        doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
        isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      }
      return Yup.string().nullable().optional();
    }),
    foreignCompanyIdGas: Yup.lazy(() => {
      if (
        doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
        isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
      ) {
        return Yup.string()
          .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
          .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
          .required();
      } else return Yup.string().nullable().optional();
    }),
    //contracts: Yup.array().of(YContract), NOTE: See YContract.js
    experts: Yup.array().of(YExpert),
    gasAgreement: Yup.bool().when('doesGasBranchExist', {
      is: (doesGasBranchExist) => {
        return doesGasBranchExist === true;
      },
      then: Yup.bool().oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
    energyAgreement: Yup.bool().when('doesEnergyBranchExist', {
      is: (doesEnergyBranchExist) => {
        return doesEnergyBranchExist === true;
      },
      then: Yup.bool().oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
  });
