import { InputField } from 'components/FormHelper/InputField';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { useNESteps } from 'context/NEStepsContext';
import { NE_ACTIVE_STEP, STORAGE_KEY } from 'global/constants';
import { TESTING_ACCOUNT_ITEM_MAP } from 'utils/FormUtils';
import React, { useMemo } from 'react';
import './FormTestingBanner.sass';
import { isProduction } from 'utils/StageUtils';
import {
  selectedTestSuiteValue,
  shouldFillNETestData,
} from 'components/NEForm/NETestdata/NETestSetup';

function NEFormTestingBanner() {
  const { activeStep } = useNESteps();

  const toggleTestSetup = () => {
    localStorage.setItem(STORAGE_KEY.FILL_NE_FORM, !shouldFillNETestData);
    window.location.reload();
  };

  const changeTestingAccount = (e) => {
    localStorage.setItem(STORAGE_KEY.NE_SELECTED_TEST_SUITE, e.target.value);
    window.location.reload();
  };

  const renderedTextForAlert = useMemo(() => {
    return activeStep !== NE_ACTIVE_STEP.BRANCH_SELECTION
      ? 'Änderungen können durch den Tester vorgenommen werden.'
      : 'Es dürfen keine Änderungen vorgenommen werden.';
  }, [activeStep]);

  if (isProduction()) {
    return null;
  } else
    return (
      <div className="test-container">
        {activeStep === NE_ACTIVE_STEP.EMAIL_INPUT ? (
          <>
            <div className="test-alert">
              <LabelCheckbox
                tooltipText="Für die Neueintragung wurden Testdaten hinterlegt. Diese können für den Testlauf genutzt werden. Dafür klicken Sie einfach auf die Checkbox und die Testdaten werden geladen und stehen Ihnen zur Auswahl bereit. Um manuell zu Testen, lassen Sie die Checkbox abgewählt. Bitte beachten Sie den Hinweisetext auf jeder Seite. Dieser gibt Auskunft darüber, ob Daten geändert werden dürfen oder nicht."
                value={shouldFillNETestData}
                name={'testSetupNE'}
                id={'testSetupNE'}
                onChange={toggleTestSetup}
                label={'Testdaten laden?'}></LabelCheckbox>
              {shouldFillNETestData ? (
                <InputField
                  fullWidth
                  select
                  items={TESTING_ACCOUNT_ITEM_MAP}
                  label={'Testfall wählen'}
                  onChange={changeTestingAccount}
                  name={`selectedTestAccount`}
                  id={`selectedTestAccount`}
                  value={selectedTestSuiteValue}
                  oldValue={''}></InputField>
              ) : null}
            </div>
          </>
        ) : shouldFillNETestData ? (
          <div className="test-alert">
            <div>
              <span className="test-alert-bold">Geladene Testdaten: </span>
              {TESTING_ACCOUNT_ITEM_MAP.find((it) => it.value === selectedTestSuiteValue).label}
            </div>
            <div>
              <span className="test-alert-bold">Bitte beachten: </span> {renderedTextForAlert}
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default NEFormTestingBanner;
