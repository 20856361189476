import * as Yup from 'yup';
import { isValid, parse, isFuture, isPast } from 'date-fns';
import { VALIDATION_CONSTANTS } from 'global/constants';

export const YDate = Yup.string()
  .transform(function (value, originalValue) {
    if (originalValue.length != 10) {
      return null;
    }

    const result = parse(originalValue, 'dd.MM.yyyy', new Date());

    if (!isValid(result)) {
      return false;
    } else {
      return result.toDateString();
    }
  })
  .typeError('Pflichtformat: dd.MM.yyyy');

export const YFutureDate = Yup.string()
  .transform(function (value, originalValue) {
    if (originalValue.length != 10) {
      return null;
    }

    const result = parse(originalValue, 'dd.MM.yyyy', new Date());

    if (!isValid(result) || !isFuture(result)) {
      return false;
    } else {
      return result.toDateString();
    }
  })
  .typeError('Pflichtformat: dd.MM.yyyy & Datum in der Zukunft wählen');

export const YPastDate = Yup.string()
  .transform(function (value, originalValue) {
    if (originalValue.length != 10) {
      return null;
    }

    const result = parse(originalValue, 'dd.MM.yyyy', new Date());

    if (!isValid(result) || !isPast(result)) {
      return false;
    } else {
      return result.toDateString();
    }
  })
  .typeError('Pflichtformat: dd.MM.yyyy & Datum in der Vergangenheit wählen');

//TODO: YZIP refactor as it's not working with numbers
export const YZip = Yup.number()
  .positive()
  .integer()
  .transform(function (value, originalValue) {
    if (originalValue.length === 0) {
      return value;
    }

    if (originalValue.length != 5) {
      return null;
    }

    if (this.isType(value)) {
      return value;
    }

    const pattern = /^\s*?\d{5}(?:[-\s]\d{4})?\s*?$/;

    const result = pattern.test(String(originalValue));

    if (result) {
      return originalValue;
    } else {
      return null;
    }
  })
  .typeError(VALIDATION_CONSTANTS.INVALID_POSTAL);
