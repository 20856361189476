import React, { useEffect, useMemo, useState } from 'react';

import './DataPolicy.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { DataPolicyContent } from './DataPolicyContent';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { DataPolicyPopup } from 'components/DataPolicyPopup/DataPolicyPopup';
import { parseAEMDatapolicyProps } from 'utils/AEMPropUtils';
import PropTypes from 'prop-types';
import { booleanToGerman } from 'utils/DataUtils';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { useMutation } from '@apollo/client';
import { UPDATE_INSTALLER_CONSENT_MUTATION } from 'Queries/Mutations';
import { createPatchEinwilligungByInstallerIdData } from 'Queries/QueriesHelper';

export const DataPolicy = (props) => {
  const { isLoading: isInstallerLoading, installer, refetchInstaller } = useCurrentInstaller();
  const [dsgvoAddressPublic, setDsgvoAddressPublic] = useState(false);
  const [dsgvoCanSendMail, setDsgvoCanSendMail] = useState(false);
  const [response, setResponse] = useState('');
  const [openSubmitPopup, setOpenSubmitPopup] = useState(false);
  const isEditor = Utils.isInEditor();
  const [updateInstallerConsentMutation] = useMutation(UPDATE_INSTALLER_CONSENT_MUTATION);

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="DataPolicy" />;
  }

  useEffect(() => {
    if (!isInstallerLoading && installer) {
      if (installer.zuAdressveroeff === 'Ja') {
        setDsgvoAddressPublic(true);
      }
      if (installer.zuInfoversand === 'Ja') {
        setDsgvoCanSendMail(true);
      }
    }
  }, [installer]);

  useEffect(() => {
    setResponse('');
  }, [dsgvoAddressPublic, dsgvoCanSendMail]);

  const submitButtonDisabled = useMemo(() => {
    return (
      openSubmitPopup ||
      isInstallerLoading ||
      (booleanToGerman(dsgvoAddressPublic) == installer?.zuAdressveroeff &&
        booleanToGerman(dsgvoCanSendMail) == installer?.zuInfoversand)
    );
  }, [isInstallerLoading, openSubmitPopup, dsgvoAddressPublic, dsgvoCanSendMail, installer]);

  const onSubmitValues = async () => {
    try {
      const response = await updateInstallerConsentMutation({
        variables: createPatchEinwilligungByInstallerIdData(dsgvoAddressPublic, dsgvoCanSendMail),
      });

      if (response.errors) {
        throw new Error(response.errors);
      }

      if (response.data.updateInstallerConsent.status !== 200) {
        throw new Error(
          'Mutation error with status: ',
          response.data.updateInstallerConsent.status
        );
      }

      setDsgvoAddressPublic(response.data.updateInstallerConsent.zuAdressveroeff === 'Ja');
      setDsgvoCanSendMail(response.data.updateInstallerConsent.zuInfoversand === 'Ja');
      setOpenSubmitPopup(true);
      refetchInstaller();
    } catch (e) {
      console.log(e);
      setResponse('Fehler beim Speichern');
    }
  };

  return (
    <div className="data-policy">
      {!installer || isInstallerLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DataPolicyContent
            dsgvoAddressPublic={dsgvoAddressPublic}
            dsgvoCanSendMail={dsgvoCanSendMail}
            dsgvoAddressPublicName="zuAdressveroeff"
            dsgvoCanSendMailName="zuInfoversand"
            onChangeDsvgoAddressPublic={(event) => {
              setDsgvoAddressPublic(event.target.value);
            }}
            onChangeDsvgoCanSendMail={(event) => {
              setDsgvoCanSendMail(event.target.value);
            }}
            documents={parseAEMDatapolicyProps(props)}
          />
          <div className="data-policy__response-message">{response}</div>
          <div className="data-policy__save-button-wrapper">
            <ActionButton
              disabled={submitButtonDisabled}
              title="Speichern"
              inverted
              type="button"
              onClick={onSubmitValues}
            />
          </div>
          <DataPolicyPopup
            openSubmitPopup={openSubmitPopup}
            setOpenSubmitPopup={setOpenSubmitPopup}
          />
        </>
      )}
    </div>
  );
};

DataPolicy.displayName = 'DataPolicy';

DataPolicy.propTypes = {
  datapolicyEDILink: PropTypes.string,
  datapolicyAVALink: PropTypes.string,
  datapolicySHNGLink: PropTypes.string,
  datapolicyBAGLink: PropTypes.string,
  datapolicyEENGLink: PropTypes.string,
  datapolicyHANSGLink: PropTypes.string,
  datapolicyNNNGLink: PropTypes.string,
  datapolicyEDILabel: PropTypes.string,
  datapolicyAVALabel: PropTypes.string,
  datapolicySHNGLabel: PropTypes.string,
  datapolicyBAGLabel: PropTypes.string,
  datapolicyEENGLabel: PropTypes.string,
  datapolicyHANSGLabel: PropTypes.string,
  datapolicyNNNGLabel: PropTypes.string,
};

DataPolicy.defaultProps = {};

MapTo(['e-fix/components/content/datapolicy'])(DataPolicy);
