import React from 'react';
import PropTypes from 'prop-types';
import { STORAGE_KEY } from 'global/constants';
import { DOCUMENT_NAME_TO_ID, SHARED_DOCUMENTS } from 'global/SharedDocumentFields';
import { isDevelopment, isLocal } from 'utils/StageUtils';
import { isNaN } from 'lodash';

export const checkKeyDown = (e) => {
  // hack for multiline text fields to accept newlines
  if (
    e.target.name !== 'changes' &&
    e.target.name !== 'questionInput' &&
    (e.code === 'Enter' || e.key === 'Enter')
  )
    e.preventDefault();
};
export const allowOnlyNumber = (e) => {
  if (e.target.value === '') {
    return e;
  } else {
    if (!/^\d+$/.test(e.target.value)) {
      return;
    }
  }
  return e;
};
export const FFormDebug = ({ values, errors, touched }) => {
  FFormDebug.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  return (
    (process.env.REACT_APP_STAGE === 'local' ||
      localStorage.getItem(STORAGE_KEY.FORM_DEBUG) === 'true') && (
      <>
        <div>Values:</div>
        <pre>{JSON.stringify(values, null, 2)}</pre>
        <div>Touched:</div>
        <pre>{JSON.stringify(touched, null, 2)}</pre>
        <div>Errors:</div>
        <pre>{JSON.stringify(errors, null, 2)}</pre>
      </>
    )
  );
};

const DUMMY_FILE_INPUT_DATA = {
  name: 'char_lengths.PNG',
  contentType: 'image/png',
  base64: 'data:image/png;base64,content',
  size: 45091,
};

export const fillFormUploadIVNE = (initialValues) => {
  initialValues.documentsTrigger.forEach((documentId) => {
    const ivDocumentKey = SHARED_DOCUMENTS[documentId].name;
    initialValues[ivDocumentKey] = [DUMMY_FILE_INPUT_DATA];
  });

  Object.keys(initialValues.expertTrigger).forEach((ivExpertKey) => {
    const expertTriggers = initialValues[ivExpertKey];
    Object.keys(expertTriggers).forEach((expertTriggerDocumentId) => {
      initialValues[ivExpertKey][expertTriggerDocumentId] = [DUMMY_FILE_INPUT_DATA];
    });
  });

  return initialValues;
};

export const fillFormUploadIVDEB = (initialValues) => {
  const expertsIds = Object.keys(initialValues).filter((key) => !isNaN(parseInt(key)));

  Object.keys(DOCUMENT_NAME_TO_ID).forEach((documentKey) => {
    expertsIds.forEach((expertId) => {
      if (initialValues[expertId][documentKey]) {
        initialValues[expertId][documentKey] = [DUMMY_FILE_INPUT_DATA];
      }
    });
    if (initialValues[documentKey]) {
      initialValues[documentKey] = [DUMMY_FILE_INPUT_DATA];
    }
    initialValues;
  });
  return initialValues;
};
export const TESTING_ACCOUNT_ITEM_MAP = [
  {
    label: `${isDevelopment() || isLocal() ? '1. ' : ''}Stamm Strom Haupt`,
    value: 'MainEnergyNonH',
  },
  { label: `${isDevelopment() || isLocal() ? '2. ' : ''}Stamm Strom Hilfs`, value: 'MainEnergyH' },
  { label: `${isDevelopment() || isLocal() ? '3. ' : ''}Stamm Gas Haupt`, value: 'MainGasNonH' },
  { label: `${isDevelopment() || isLocal() ? '4. ' : ''}Stamm Gas Hilfs`, value: 'MainGasH' },
  {
    label: `${isDevelopment() || isLocal() ? '5. ' : ''}Stamm Strom Haupt - Stamm Gas Haupt`,
    value: 'MainEnergyNonHMainGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '6. ' : ''}Stamm Strom Hilfs - Stamm Gas Haupt`,
    value: 'MainEnergyHMainGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '7. ' : ''}Stamm Strom Haupt - Stamm Gas Hilfs`,
    value: 'MainEnergyNonHMainGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '8. ' : ''}Stamm Strom Hilfs - Stamm Gas Hilfs`,
    value: 'MainEnergyHMainGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '9. ' : ''}Stamm Strom Haupt - Gast Gas Haupt`,
    value: 'MainEnergyNonHGuestGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '10. ' : ''}Stamm Strom Hilfs - Gast Gas Haupt`,
    value: 'MainEnergyHGuestGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '11. ' : ''}Stamm Strom Haupt - Gast Gas Hilfs`,
    value: 'MainEnergyNonHGuestGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '12. ' : ''}Stamm Strom Hilfs - Gast Gas Hilfs`,
    value: 'MainEnergyHGuestGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '13. ' : ''}Gast Strom Haupt - Stamm Gas Haupt`,
    value: 'GuestEnergyNonHMainGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '14. ' : ''}Gast Strom Haupt - Stamm Gas Hilfs`,
    value: 'GuestEnergyNonHMainGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '15. ' : ''}Gast Strom Hilfs - Stamm Gas Haupt`,
    value: 'GuestEnergyHMainGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '16. ' : ''}Gast Strom Hilfs - Stamm Gas Hilfs`,
    value: 'GuestEnergyHMainGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '17. ' : ''}Gast Strom Haupt`,
    value: 'GuestEnergyNonH',
  },
  { label: `${isDevelopment() || isLocal() ? '18. ' : ''}Gast Gas Haupt`, value: 'GuestGasNonH' },
  { label: `${isDevelopment() || isLocal() ? '19. ' : ''}Gast Strom Hilfs`, value: 'GuestEnergyH' },
  { label: `${isDevelopment() || isLocal() ? '20. ' : ''}Gast Gas Hilfs`, value: 'GuestGasH' },
  {
    label: `${isDevelopment() || isLocal() ? '21. ' : ''}Gast Strom Haupt - Gast Gas Haupt`,
    value: 'GuestEnergyNonHGuestGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '22. ' : ''}Gast Strom Hilfs - Gast Gas Haupt`,
    value: 'GuestEnergyHGuestGasNonH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '23. ' : ''}Gast Strom Haupt - Gast Gas Hilfs`,
    value: 'GuestEnergyNonHGuestGasH',
  },
  {
    label: `${isDevelopment() || isLocal() ? '24. ' : ''}Gast Strom Hilfs - Gast Gas Hilfs`,
    value: 'GuestEnergyHGuestGasH',
  },
];
