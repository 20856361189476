import { useQuery } from '@apollo/client';
import {
  DEB_CURRENT_TEST_SUITE,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { GET_INSTALLER_EXPERTS_QUERY } from 'Queries/Queries';
import { useContext, useEffect, useMemo } from 'react';

export const useCurrentExperts = () => {
  const { setShowError } = useContext(ErrorHandlingContext);
  const { data, loading, error } = useQuery(GET_INSTALLER_EXPERTS_QUERY);

  const experts = useMemo(() => {
    return shouldFillDebTestData
      ? DEB_CURRENT_TEST_SUITE.installerData.experts
      : data?.getInstallerExperts ?? null;
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('useCurrentExperts error:', error);
      setShowError((prev) => !prev && true); // Prevent multiple triggers
    }
  }, [error, setShowError]);

  return { experts, isLoading: loading };
};
