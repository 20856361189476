import { InputField } from 'components/FormHelper/InputField';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { DEB_ACTIVE_PAGE, STORAGE_KEY } from 'global/constants';
import { TESTING_ACCOUNT_ITEM_MAP } from 'utils/FormUtils';
import React from 'react';
import './FormTestingBanner.sass';
import { isProduction } from 'utils/StageUtils';
import {
  selectedTestSuiteValue,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import PropTypes from 'prop-types';
function DebFormTestingBanner({ activePage }) {
  const toggleTestSetup = () => {
    localStorage.setItem(STORAGE_KEY.FILL_DEB_FORM, !shouldFillDebTestData);

    window.location.reload();
  };

  const changeTestingAccount = (e) => {
    localStorage.setItem(STORAGE_KEY.DEB_SELECTED_TEST_SUITE, e.target.value);
    window.location.reload();
  };

  if (isProduction()) {
    return null;
  } else
    return (
      <div className="test-container">
        {activePage === DEB_ACTIVE_PAGE.FORM_PAGE ? (
          <>
            <div className="test-alert">
              <LabelCheckbox
                tooltipText="Für das Testing im DEB werden eingehende Daten simuliert. Es können Änderungen vom Tester vorgenommen werden."
                name={'testSetupDEB'}
                id={'testSetupDEB'}
                value={shouldFillDebTestData}
                onChange={toggleTestSetup}
                label={'Testdaten laden?'}></LabelCheckbox>
              {shouldFillDebTestData ? (
                <InputField
                  fullWidth
                  select
                  items={TESTING_ACCOUNT_ITEM_MAP}
                  label={'Testfall wählen'}
                  onChange={changeTestingAccount}
                  name={`selectedTestAccount`}
                  id={`selectedTestAccount`}
                  value={selectedTestSuiteValue}
                  oldValue={''}></InputField>
              ) : null}
            </div>
          </>
        ) : shouldFillDebTestData ? (
          <div className="test-alert">
            <div>
              <span className="test-alert-bold">Geladene Testdaten: </span>{' '}
              {TESTING_ACCOUNT_ITEM_MAP.find((it) => it.value === selectedTestSuiteValue).label}
            </div>
            <div>
              <span className="test-alert-bold">Bitte beachten: </span> Änderungen können durch den
              Tester vorgenommen werden
            </div>
          </div>
        ) : null}
      </div>
    );
}
DebFormTestingBanner.propTypes = {
  activePage: PropTypes.number,
};
export default DebFormTestingBanner;
