import React, { useEffect } from 'react';
import './NEStepBranchSelection.sass';
import { useNESteps } from 'context/NEStepsContext';

import { FormActionButtons } from 'components/FormHelper/FormActionButtons';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import energyIcon from '../../../../assets/pics/energy_icon.svg';
import gasIcon from '../../../../assets/pics/gas_icon.svg';
import { InputRow } from 'components/FormHelper/InputRow';
import { useFormik } from 'formik';
import { NEBranchInformation } from './NEBranchInformation';
import { FFormDebug } from 'utils/FormUtils';
import { YNEBranchSelectionValidation } from 'components/NEValidation/NEBranchSelectionValidation';
import { NE_CURRENT_TEST_SUITE, shouldFillNETestData } from 'components/NEForm/NETestdata/NETestSetup';
import { BRANCH_TYPE, NE_ACTIVE_STEP } from 'global/constants';

const NEStepBranchSelection = () => {
  const {
    activeStep,
    setActiveStep,
    setSubmittedValuesWithStep,
    getSubmittedValuesWithStep,
    clearSubmittedValuesWithStep,
  } = useNESteps();
  const formik = useFormik({
    initialValues: shouldFillNETestData
      ? NE_CURRENT_TEST_SUITE.step1
      : {
          isEnergyActive: false,
          isGasActive: false,
          companyTypeEnergy: '',
          companyTypeGas: '',
          branchTypeEnergy: '',
          branchTypeGas: '',
        },
    validationSchema: YNEBranchSelectionValidation,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
  });

  useEffect(() => {
    if (formik.values.isEnergyActive === false) {
      formik.setFieldValue('companyTypeEnergy', '');
      formik.setFieldValue('branchTypeEnergy', '');
    }
    if (formik.values.isGasActive === false) {
      formik.setFieldValue('companyTypeGas', '');
      formik.setFieldValue('branchTypeGas', '');
    }
  }, [formik.values.isEnergyActive, formik.values.isGasActive]);

  if (activeStep !== NE_ACTIVE_STEP.BRANCH_SELECTION) return null;

  const onBack = () => {
    setActiveStep(NE_ACTIVE_STEP.EMAIL_INPUT);
  };

  const onSubmit = () => {
    const currentContextValues = getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION);
    const { values } = formik;
    const valuesChanged = Object.keys(values).some(
      (key) => values[key] !== currentContextValues[key]
    );

    if (valuesChanged) {
      clearSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM);
      setSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION, formik.values);
    }

    setActiveStep(NE_ACTIVE_STEP.FORM);
  };

  return (
    <>
      <div className="ne-step-branch-selection">
        <div className="ne-step-branch-selection-hero">Bitte wählen Sie Ihre Sparte(n) aus:</div>
        <InputRow>
          <LabelCheckbox
            label="Strom"
            id="isEnergyActive"
            name="isEnergyActive"
            error={Boolean(formik.errors?.noBranchSelected)}
            icon={energyIcon}
            value={formik.values.isEnergyActive}
            onChange={formik.handleChange}></LabelCheckbox>
          <LabelCheckbox
            label="Gas"
            id="isGasActive"
            name="isGasActive"
            error={Boolean(formik.errors?.noBranchSelected)}
            icon={gasIcon}
            value={formik.values.isGasActive}
            onChange={formik.handleChange}></LabelCheckbox>
        </InputRow>
        {formik.values.isEnergyActive ? (
          <NEBranchInformation formik={formik} branch={BRANCH_TYPE.ENERGY}></NEBranchInformation>
        ) : null}
        {formik.values.isGasActive ? (
          <NEBranchInformation formik={formik} branch={BRANCH_TYPE.GAS}></NEBranchInformation>
        ) : null}
      </div>
      <FFormDebug values={formik.values} errors={formik.errors}></FFormDebug>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={Object.keys(formik.errors).length > 0}
          titleLeft="Zurück"
          titleRight={activeStep === NE_ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={
            formik.errors.noBranchSelected
              ? formik.errors.noBranchSelected
              : !formik.isValid
              ? 'Füllen Sie das Formular vollständig aus'
              : ''
          }
        />
      </div>
    </>
  );
};

export { NEStepBranchSelection };
