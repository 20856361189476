import React, { useEffect, useState } from 'react';
import './NEStepFormUpload.sass';
import { useNESteps } from 'context/NEStepsContext';

import { FormActionButtons } from 'components/FormHelper/FormActionButtons';
import { FormikProvider, useFormik } from 'formik';
import { getTenantLandingPage } from 'utils/UrlUtil';
import { checkKeyDown, FFormDebug, fillFormUploadIVNE } from 'utils/FormUtils';
import {
  createEmailSubjectForNE,
  getInstallerEntryByBranchSelection,
  getInstallerEntryByExpert,
  prepNEDataForBackend,
} from 'components/NEForm/Utils/NEDataUtils';
import { useMutation } from '@apollo/client';
import { PUBLIC_SEND_NE_FORM } from 'Queries/Mutations';
import { useNEFormUploadIV } from './NEFormUploadIV';
import { useMemo } from 'react';
import { calculateTotalFilesSize, getFileWithNames } from 'utils/FileUtil';
import {
  FORM_CONSTANTS,
  NE_ACTIVE_STEP,
  TENANT_ID,
  UPLOAD_RESTRICTION_ERROR_MESSAGE,
} from 'global/constants';
import PropTypes from 'prop-types';
import { FormPageWrapper } from 'components/FormHelper/FormPageWrapper';
import { FormSection } from 'components/FormHelper/FormSection';
import { InputRow } from 'components/FormHelper/InputRow';
import { UploadField } from 'components/FormHelper/UploadField';
import { InputField } from 'components/FormHelper/InputField';
import { SubmitPopup } from 'components/FormHelper/SubmitPopup';
import { DataPolicyContent } from 'components/DataPolicy/DataPolicyContent';
import { HintText } from 'components/HintText/HintText';
import { HINTTEXT_TYPE } from 'components/HintText/HintTextType';
import { DOCUMENT_NAME_TO_ID } from 'global/SharedDocumentFields';
import {
  NE_DOCUMENTS_FIELDS,
  NE_EXPERTS_DOCUMENTS_FIELDS,
} from 'components/NEForm/Utils/DocumentsFields';
import { getTenantId } from 'utils/tenantUtil';
import { PUBLIC_NO_AUTH_CONFIG } from 'components/ApolloComponent/Apollo';
import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';
import { evaluateCondition } from 'global/fieldValidationFunction';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { v4 as uuidv4 } from 'uuid';
import { shouldFillNETestData } from 'components/NEForm/NETestdata/NETestSetup';

const NEStepFormUpload = ({
  dataPolicyDocuments,
  selfDeclarationCompanyFacilityLink,
  installerContractLink,
  installerContractLinkNNNGLink,
  installerContractLinkEENGLink,
  installerContractLinkHANSGLink,
  expertQualificationCertificateGasLink,
  expertQualificationCertificateEnergyLink,
  installerContractMeterInstallationGasLink,
  installerContractMeterInstallationEnergyLink,
  installerContractSealing,
}) => {
  const { activeStep, setActiveStep, getSubmittedValuesWithStep } = useNESteps();
  const [response, setResponse] = useState('');
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadRestrictionError, setUploadRestrictionError] = useState('');

  const [sendNeForm] = useMutation(PUBLIC_SEND_NE_FORM, PUBLIC_NO_AUTH_CONFIG);

  const branchSelectionValues = getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION);

  const formValues = getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM);
  const installerEntry = useMemo(
    () => getInstallerEntryByBranchSelection(branchSelectionValues),
    [branchSelectionValues]
  );

  const { initialValues, validationSchema } = useNEFormUploadIV(
    formValues,
    installerEntry,
    branchSelectionValues
  );
  const formik = useFormik({
    initialValues: shouldFillNETestData ? fillFormUploadIVNE(initialValues) : initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const { values, errors, touched, handleSubmit } = formik;
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [values, errors, formik]);

  const onBack = () => {
    setActiveStep(NE_ACTIVE_STEP.FORM);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const subject = createEmailSubjectForNE(
      installerEntry,
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
    );

    const files = getFileWithNames(values, getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM), {
      id: `NE${uuidv4()}`,
    });

    const formData = prepNEDataForBackend(
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM),
      formik.values,
      subject,
      files,
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
    );

    sendNeForm({
      variables: {
        neInput: formData,
      },
    })
      .then(({ data }) => {
        setSubmitPopupOpen(true);
        setResponse(data.ne.status); //this can't be a constant as graphql sometimes goes to 200 even with errors
      })
      .catch(() => {
        setSubmitPopupOpen(true);
        setResponse('ERROR');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const getSectionLink = (section) => {
    //check documentFields.jsx and HWPORTAL-3692/HWPORTAL-3925 to get more info on the sections. Match text on the story with infoText on the fields
    switch (section.id) {
      case DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation:
        return selfDeclarationCompanyFacilityLink;
      case DOCUMENT_NAME_TO_ID.installerContract:
        if (getTenantId() === TENANT_ID.SHNG) {
          return [
            installerContractLinkEENGLink,
            installerContractLinkHANSGLink,
            installerContractLinkNNNGLink,
            installerContractLink,
          ];
        } else {
          return installerContractLink;
        }
      case DOCUMENT_NAME_TO_ID.installerContractGasResponsible:
        return installerContractLink;
      case DOCUMENT_NAME_TO_ID.qualificationProofElectricity:
        return expertQualificationCertificateEnergyLink;
      case DOCUMENT_NAME_TO_ID.qualificationProofGas:
        return expertQualificationCertificateGasLink;
      case DOCUMENT_NAME_TO_ID.installerContractMeterEnergy:
        return installerContractMeterInstallationEnergyLink;
      case DOCUMENT_NAME_TO_ID.installerContractMeterGas:
        return installerContractMeterInstallationGasLink;
      case DOCUMENT_NAME_TO_ID.installerContractSealing:
        return installerContractSealing;
    }
  };

  useEffect(() => {
    //total file size calculation and validation
    let totalSize = calculateTotalFilesSize(values);
    if (totalSize > FORM_CONSTANTS.MAX_UPLOAD_TOTAL_FILE_SIZE) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_FILE_SIZE);
    } else {
      setUploadRestrictionError('');
    }

    formik.validateForm();
  }, [values]);

  if (activeStep !== NE_ACTIVE_STEP.FORM_UPLOAD) return null;

  return (
    <>
      <div className="ne-step-form-upload">
        <FormikProvider value={formik}>
          <form onKeyDown={checkKeyDown} onSubmit={handleSubmit}>
            <FormPageWrapper>
              <FormSection
                key={'header0'}
                title={'Dokumentenupload und weitere Angaben'}
                infoText={
                  <>
                    <div className="ne-step-form-upload__page-info-text">
                      Bitte laden Sie die geforderten Dokumente hoch. Aufgrund Ihrer Auswahl auf der
                      vorherigen Seite benötigen wir folgende Dokumente. Am Ende überprüfen Sie
                      bitte Ihre Datenschutzeinstellung und senden Sie uns nach Bedarf Ihre Fragen.
                    </div>
                    <br />
                    <HintText>
                      Folgende Formate werden beim Upload unterstützt: PDF, JPG, JPEG, GIF, PNG
                    </HintText>
                  </>
                }
              />
              {NE_DOCUMENTS_FIELDS[installerEntry][getTenantId()]
                .filter((section) =>
                  section.whenShouldFieldRender
                    ? evaluateCondition(section.whenShouldFieldRender, {
                        branchSelectionValues,
                        isOnlyHilfsbetrieb:
                          isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
                        form: FORM_CONSTANTS.NE,
                      })
                    : true
                )
                .map((section) => {
                  let infoText = section.infoTextNE || section.infoText;
                  let infoLink = getSectionLink(section);
                  return (
                    <FormSection
                      key={section.id}
                      subtitle={`·  ${section.title} ${
                        evaluateCondition(section.isOptional, {
                          isOnlyHilfsbetrieb:
                            isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
                          form: FORM_CONSTANTS.NE,
                        })
                          ? ''
                          : '*'
                      }`}
                      tooltip={section.tooltip}
                      navigationTooltip={section.navigationTooltip}>
                      <InputRow>
                        <UploadField
                          infoText={infoText}
                          key={section.name}
                          name={section.name}
                          optional={
                            section.optional ||
                            section.optionalNE ||
                            (section.optionalForHilfsbetrieb &&
                              isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues))
                          }
                          files={values[section.name]}
                          formik={formik}
                          infoLink={infoLink}
                        />
                      </InputRow>
                    </FormSection>
                  );
                })}

              {Object.keys(values.expertTrigger).map((key) => {
                const expert = formValues.experts?.find((expert) => {
                  return expert.id == key;
                });
                const fields = [];
                NE_EXPERTS_DOCUMENTS_FIELDS[
                  getInstallerEntryByExpert(expert, branchSelectionValues)
                ][getTenantId()].forEach((section) => {
                  if (!values.expertTrigger[key].includes(section.id)) return;
                  let infoText = section.infoTextNE || section.infoText;
                  let infoLink = getSectionLink(section);
                  fields.push(
                    <FormSection
                      key={section.id}
                      subtitle={`·  ${section.title} ${
                        evaluateCondition(section.isOptional, {
                          isOnlyHilfsbetrieb:
                            isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
                          form: FORM_CONSTANTS.NE,
                        })
                          ? ''
                          : '*'
                      }`}
                      tooltip={section.tooltip}
                      navigationTooltip={section.navigationTooltip}>
                      <InputRow>
                        <UploadField
                          infoText={infoText}
                          key={`${key}.${section.name}`}
                          name={`${key}.${section.name}`}
                          files={values[key]?.[section.name] || []}
                          onChange={formik.handleChange}
                          formik={formik}
                          error={Boolean(formik.errors[section.name])}
                          helperText={formik.errors[section.name] || ''}
                          infoLink={infoLink}
                        />
                      </InputRow>
                    </FormSection>
                  );
                });
                if (fields.length > 0) {
                  const expert = formValues.experts?.find((expert) => {
                    return expert.id == key;
                  });
                  if (expert)
                    return (
                      <FormSection
                        title={`${expert.lastName}  ${expert.firstName}`}
                        key={expert.id}>
                        {fields}
                      </FormSection>
                    );
                }
              })}
              <FormSection key={'changes'} title={'Weitere Angaben'}>
                <div>
                  Sie haben noch Fragen oder weitere Anmerkungen? Dann können Sie uns hier eine
                  Nachrichten hinterlassen.
                </div>
                <InputField
                  key={'changes'}
                  name={'changes'}
                  fullWidth
                  label={'Freitext'}
                  oldValue={''}
                  variant="standard"
                  value={values['changes']}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors['changes'])}
                  helperText={formik.errors['changes'] || ''}
                  multiline
                  rows={6}
                  rowsMax={6}
                />
              </FormSection>
              <FormSection key={'changesAndPrivacy'} title={'Datenschutz'}>
                <div>Hier können Sie ihre Zustimmungen bearbeiten</div>
                <div className="agreement-section__wrapper">
                  <DataPolicyContent
                    dsgvoAddressPublic={values.isAddressPublic}
                    dsgvoCanSendMail={values.canSendMail}
                    dsgvoAddressPublicName="isAddressPublic"
                    dsgvoCanSendMailName="canSendMail"
                    onChangeDsvgoAddressPublic={formik.handleChange}
                    onChangeDsvgoCanSendMail={formik.handleChange}
                    documents={dataPolicyDocuments}
                  />
                </div>
              </FormSection>
            </FormPageWrapper>

            {uploadRestrictionError && (
              <div className="ne-form__upload-restriction-error">
                <HintText type={HINTTEXT_TYPE.ERROR}>{uploadRestrictionError}</HintText>
              </div>
            )}
          </form>
        </FormikProvider>
        <FFormDebug values={values} touched={touched} errors={errors} />
      </div>
      <div className="ne-form__action-buttons">
        {isSubmitting ? (
          <LoadingIndicator />
        ) : (
          <FormActionButtons
            hasErrors={hasErrors || Boolean(uploadRestrictionError)}
            titleLeft="Zurück"
            titleRight={activeStep === NE_ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
            onClickLeft={onBack}
            onClickRight={onSubmit}
            errorMessage="Bitte füllen Sie alle mit * gekennzeichneten Pflichtfelder sowie Uploadfelder."
          />
        )}
      </div>
      <SubmitPopup
        type="NEW_ENTRY"
        loading={isSubmitting}
        popupOpen={submitPopupOpen}
        setPopupOpen={() => setSubmitPopupOpen(false)}
        response={response}
        redirectFullOnSuccessOrCancel={getTenantLandingPage()}
        redirectCancelButtonText="Zurück zum Login"
        onErrorRetry={() => {
          onSubmit();
        }}></SubmitPopup>
    </>
  );
};

NEStepFormUpload.propTypes = {
  dataPolicyDocuments: PropTypes.array,
  selfDeclarationCompanyFacilityLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  expertQualificationCertificateGasLink: PropTypes.string,
  expertQualificationCertificateEnergyLink: PropTypes.string,
  installerContractMeterInstallationGasLink: PropTypes.string,
  installerContractMeterInstallationEnergyLink: PropTypes.string,
  installerContractSealing: PropTypes.string,
  installerContractLink: PropTypes.string,
  installerContractLinkNNNGLink: PropTypes.string,
  installerContractLinkEENGLink: PropTypes.string,
  installerContractLinkHANSGLink: PropTypes.string,
};

export { NEStepFormUpload };
