/* eslint-disable react/prop-types */
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import React, { useEffect, useState } from 'react';
import Truncate from 'react-truncate';
import CalendarIcon from '../../../assets/pics/Calendar.svg';
import LocationIcon from '../../../assets/pics/locationIcon.svg';
import { ReactComponent as ParticipantsIcon } from '../../../assets/pics/participantsIcon.svg';
import TickIcon from '../../../assets/pics/tick.svg';
import ClockIcon from '../../../assets/pics/clock.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/pics/downloadIcon.svg';
import { formatDate } from 'utils/DatesUtils';
import Tags from '../../Tags/Tags';
import './EventMetaData.sass';
import { isToday } from 'date-fns';
import { CustomModal } from 'components/CustomModal';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { useMutation } from '@apollo/client';
import { UNREGISTER_FROM_EVENT_MUTATION } from 'Queries/Mutations';
import { getEventsOverviewPath } from 'utils/UrlUtil';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import isNewEntry from 'components/OverviewLists/utils/isNewEntry';
import { getIsRegistrationExpired } from 'utils/EventDateUtils';

const EventMetaData = ({ event, isOverview = false }) => {
  const {
    title,
    maxParticipants,
    registration,
    description,
    location,
    eventDays,
    tags,
    attachmentUrl,
    registrationDeadline,
    meetingLink,
    disableMaxParticipation = false,
  } = event;

  const { eventParticipantsNumber, participants } = registration;

  const isRegistrationExpired = getIsRegistrationExpired(event);

  const history = useHistory();
  const [unregisterFromEvent] = useMutation(UNREGISTER_FROM_EVENT_MUTATION);

  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [modalCtaButtons, setModalCtaButtons] = useState();

  const closePopupCta = (
    <ActionButton
      onClick={() => {
        setIsPopupOpen(false);
        history.push(getEventsOverviewPath());
      }}
      title="Abbrechen"
      inverted
    />
  );

  const handleCancelEventClick = async () => {
    const variables = { eventId: event.id, unregister: true, participants: [] };
    unregisterFromEvent({ variables })
      .then(() => {
        history.push(getEventsOverviewPath());
        setIsPopupOpen(false);
      })
      .catch(() => {
        setModalContent({
          title: 'Fehler!',
          content: 'Problem bei der Anfrage. Bitte versuchen Sie es später erneut.',
        });
        setModalCtaButtons(closePopupCta);
      });
  };

  useEffect(() => {
    setModalCtaButtons(
      <>
        <ActionButton onClick={handleCancelEventClick} title="Absagen" inverted />
        {closePopupCta}
      </>
    );
  }, []);

  const [modalContent, setModalContent] = useState({
    title: 'Wirklich absagen?',
    content: 'Willst du den Termin wirklich für dich und alle deine Teilnehmer absagen?',
  });

  const handleTruncate = (truncatedNow) => {
    if (truncated !== truncatedNow) {
      setTruncated(truncatedNow);
    }
  };

  function getSanitzedAttachmentUrl(attachmentUrl) {
    const NEEDLE = 'http://localhost:4502';
    const NODE_ENV_DEV = 'development';

    if (
      !attachmentUrl ||
      process.env.NODE_ENV === NODE_ENV_DEV ||
      !process.env.REACT_APP_GRAPHQL_ENDPOINT ||
      !process.env.REACT_APP_AEM_ENDPOINT.length ||
      attachmentUrl.indexOf(NEEDLE) === -1
    ) {
      return attachmentUrl;
    }

    return attachmentUrl.replace(NEEDLE, process.env.REACT_APP_AEM_ENDPOINT);
  }

  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="event-meta-data">
      <CustomModal
        title={modalContent.title}
        content={
          <div className="event-register-form__modal-content-wrapper">{modalContent.content}</div>
        }
        ctaButtons={modalCtaButtons}
        isOpen={isPopupOpen}
      />
      <div className="event-meta-data__description">
        <div className="event-meta-data__header">
          <Tags tags={tags} inverted={isOverview && isNewEntry(event.tags)} />
        </div>
        <div className="event-meta-data__title">{title}</div>
        <div className="event-meta-data__text">
          <Truncate
            lines={!expanded && 3}
            ellipsis={
              <div>
                {' '}
                <ExpandMoreTwoToneIcon fontSize="large" onClick={toggleLines} />{' '}
                <u>
                  <a href="#" onClick={toggleLines}>
                    Mehr anzeigen
                  </a>
                </u>
              </div>
            }
            onTruncate={handleTruncate}>
            {description}
          </Truncate>
          {!truncated && expanded && (
            <div>
              {' '}
              <ExpandLessTwoToneIcon className="less" fontSize="large" onClick={toggleLines} />{' '}
              <u>
                <a href="#" onClick={toggleLines}>
                  Weniger anzeigen
                </a>
              </u>
            </div>
          )}
        </div>
      </div>
      <div className="event-meta-data__informations">
        <div className="event-meta-data__column">
          <div className="event-meta-data__section">
            <div className="event-meta-data__icon-container">
              <img alt="Clock icon" className="event-meta-data__icon" src={CalendarIcon} />
            </div>
            <div className="event-meta-data__text-container">
              {eventDays.map((item, index) => (
                <div key={`${item.startDate}.${index}`}>
                  <div>
                    Veranstaltungstag{' '}
                    <span className="event-meta-data__text-container__text-strong">
                      {formatDate(item.date)}
                    </span>
                  </div>
                  <div>
                    Von{' '}
                    <span className="event-meta-data__text-container__text-strong">
                      {item.startTime}
                    </span>{' '}
                    bis{' '}
                    <span className="event-meta-data__text-container__text-strong">
                      {item.endTime}{' '}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="event-meta-data__section">
            <div className="event-meta-data__icon-container">
              <img alt="Location icon" src={LocationIcon} className="event-meta-data__icon" />
            </div>
            <div className="event-meta-data__text-container">
              {meetingLink ? (
                <>
                  <div className="event-meta-data__text-container__text-subtitle">
                    Online-Veranstaltung
                  </div>
                  {participants.length ? (
                    <>
                      {isToday(new Date(event.startDate)) ? (
                        <a
                          href={meetingLink}
                          target="_blank"
                          rel="noreferrer"
                          className="event-meta-data__item-link">
                          Jetzt teilnehmen
                        </a>
                      ) : (
                        <>
                          <div>Link wird am Veranstaltungstag hier freigeschaltet.</div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>Link nach Anmeldung</div>
                  )}
                </>
              ) : (
                <div className="event-meta-data__text-container">
                  <div className="event-meta-data__text-container__text-subtitle">
                    {location.description ? (
                      location.description
                    ) : (
                      <>Veranstaltungsort / Lokalität</>
                    )}
                  </div>
                  <div>
                    {location.street} {location.streetNumber}
                  </div>
                  <div>
                    {location.zip} {location.city} {location.name}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="event-meta-data__column">
          {registrationDeadline ? (
            <div className="event-meta-data__section">
              <div className="event-meta-data__icon-container">
                <img alt="Tick icon" src={ClockIcon} className="event-meta-data__icon" />
              </div>
              <div className="event-meta-data__text-container__horizontal">
                Anmeldeschluss:
                <span className="event-meta-data__text-container__text-strong">
                  {` ${formatDate(registrationDeadline)}`}
                </span>
              </div>
            </div>
          ) : null}
          {participants.length > 0 ? (
            <div className="event-meta-data__section">
              <div className="event-meta-data__icon-container">
                <img alt="Tick icon" src={TickIcon} className="event-meta-data__icon" />
              </div>
              <div className="event-meta-data__text-container">
                <div>{`Teilnahme mit ${participants.length} ${
                  participants.length > 1 ? 'Personen' : 'Person'
                }`}</div>
              </div>
            </div>
          ) : null}

          {attachmentUrl && participants.length > 0 ? (
            <div className="event-meta-data__section">
              <a
                className="event-meta-data__section"
                href={getSanitzedAttachmentUrl(attachmentUrl)}
                download>
                <div className="event-meta-data__icon-container">
                  <DownloadIcon alt="Download icon" className="event-meta-data__icon__link" />
                </div>

                <div className="event-meta-data__item-link">
                  <u>Anhang zum Download</u>
                </div>
              </a>
            </div>
          ) : null}
          {isRegistrationExpired || disableMaxParticipation ? null : (
            <div className="event-meta-data__section">
              <div className="event-meta-data__icon-container">
                <ParticipantsIcon alt="Participants icon" className="event-meta-data__icon" />
              </div>

              <div className="event-meta-data__text-container">
                <div>
                  {maxParticipants > eventParticipantsNumber ? (
                    <div>
                      Noch{' '}
                      {maxParticipants - eventParticipantsNumber == 1 ? (
                        <>
                          <span className="event-meta-data__text-container__text-strong">1 </span>
                          freier Platz
                        </>
                      ) : (
                        <>
                          <span className="event-meta-data__text-container__text-strong">
                            {maxParticipants - eventParticipantsNumber}{' '}
                          </span>
                          freie Plätze
                        </>
                      )}
                    </div>
                  ) : (
                    <>Die Veranstaltung ist leider ausgebucht</>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="event-meta-data__section"></div>
          {!isOverview ? (
            <div className="event-meta-data__section">
              {registration.participants.length > 0 && (
                <div className="event-meta-data__item-link" onClick={() => setIsPopupOpen(true)}>
                  {' '}
                  Teilnahme absagen
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EventMetaData;
