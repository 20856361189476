import { useQuery } from '@apollo/client';
import {
  DEB_CURRENT_TEST_SUITE,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { GET_INSTALLER_CONTRACTS_QUERY } from 'Queries/Queries';
import { useContext, useEffect, useMemo } from 'react';

export const useCurrentContracts = () => {
  const { setShowError } = useContext(ErrorHandlingContext);
  const { data, loading, error } = useQuery(GET_INSTALLER_CONTRACTS_QUERY);

  const contracts = useMemo(() => {
    return shouldFillDebTestData
      ? DEB_CURRENT_TEST_SUITE.installerData.contracts
      : data?.getInstallerContracts ?? null;
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('useCurrentContracts error:', error);
      setShowError((prev) => !prev && true); // Prevent multiple triggers
    }
  }, [error, setShowError]);

  return { contracts, isLoading: loading };
};
