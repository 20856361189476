import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import './DebForm.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { YDebFormSchema } from './ValidationStructure/YDebFormSchema';
import { DebFormFirstPage } from './DebFormFirstPage';
import PropTypes from 'prop-types';

import { DebFormSecondPage } from './DebFormSecondPage';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useCurrentExperts } from 'hooks/UseCurrentExperts';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import {
  parseAEMDatapolicyProps,
  parseAEMInstallerContractProps,
  parseAEMSelfDeclarationCompanyFacilityProps,
} from 'utils/AEMPropUtils';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { getInstallerStatusSummary } from 'utils/InstallerUtils';
import { prepareDEBCompleteData } from './Utils/DebFormDataUtil';
import DebFormTestingBanner from 'components/FormHelper/DebFormTestingBanner';
import { DEB_ACTIVE_PAGE } from 'global/constants';
import { useCurrentContracts } from 'hooks/UseCurrentContracts';
import { useCurrentSeals } from 'hooks/UseCurrentSeals';
import { isUndefined } from 'lodash';

export const DebForm = (props) => {
  const [page, setPage] = useState(DEB_ACTIVE_PAGE.FORM_PAGE);
  const [initialValues, setInitialValues] = useState();
  const [installerStatus, setInstallerStatus] = useState();
  const { installer, isLoading: isInstallerLoading } = useCurrentInstaller();
  const { experts, isLoading: isExpertsLoading } = useCurrentExperts();
  const { contracts, isLoading: isContractsLoading } = useCurrentContracts();
  const { seals, isLoading: isSealsLoading } = useCurrentSeals();

  useEffect(() => {
    if (installer) {
      const installerStatus = getInstallerStatusSummary(installer);
      setInstallerStatus(installerStatus);
    }
  }, [installer]);

  useEffect(() => {
    if (
      !isInstallerLoading &&
      installer &&
      !isExpertsLoading &&
      !isUndefined(experts) &&
      !isContractsLoading &&
      !isUndefined(contracts) &&
      !isSealsLoading &&
      !isUndefined(seals)
    ) {
      prepareDEBCompleteData(installer, experts, contracts, seals, installerStatus).then(
        (finalObject) => {
          setInitialValues((prev) => {
            return { ...prev, ...finalObject };
          });
        }
      );
    }
  }, [
    isInstallerLoading,
    isExpertsLoading,
    isContractsLoading,
    isSealsLoading,
    installer,
    experts,
    contracts,
    seals,
  ]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: YDebFormSchema(installerStatus, initialValues?.doesNeedAvaGuestPostal),
    enableReinitialize: true,
    validateOnChange: true,
  });

  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="DebForm" />;
  }

  return (
    <div className="deb-form">
      <div className="deb-form__container">
        <DebFormTestingBanner activePage={page}></DebFormTestingBanner>
        {formik.values ? (
          page === DEB_ACTIVE_PAGE.FORM_PAGE ? (
            <DebFormFirstPage
              installer={installer}
              formik={formik}
              initialValues={initialValues}
              energyAgreementLink={props.energyAgreementLink}
              gasAgreementLink={props.gasAgreementLink}
              setPage={setPage}
            />
          ) : (
            <DebFormSecondPage
              initialValuesFirstPage={initialValues}
              valuesFirstPage={formik.values}
              installer={installer}
              setPage={setPage}
              expertQualificationCertificateGasLink={props.expertQualificationCertificateGasLink}
              expertQualificationCertificateEnergyLink={
                props.expertQualificationCertificateEnergyLink
              }
              installerContractLink={parseAEMInstallerContractProps(props, installer)}
              selfDeclarationCompanyFacilityLink={parseAEMSelfDeclarationCompanyFacilityProps(
                props
              )}
              dataPolicyDocuments={parseAEMDatapolicyProps(props)}
            />
          )
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};

DebForm.displayName = 'DebForm';

DebForm.propTypes = {
  expertQualificationCertificateGasLink: PropTypes.string,
  expertQualificationCertificateEnergyLink: PropTypes.string,
  installerContractLink: PropTypes.string,
  installerContractLinkEENG: PropTypes.string,
  installerContractLinkNNNG: PropTypes.string,
  installerContractLinkHANSG: PropTypes.string,
  selfDeclarationCompanyFacilityLink: PropTypes.string,
  selfDeclarationCompanyFacilityNNNGLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  energyAgreementLink: PropTypes.string,
  datapolicyEDILink: PropTypes.string,
  datapolicyAVALink: PropTypes.string,
  datapolicySHNGLink: PropTypes.string,
  datapolicyBAGLink: PropTypes.string,
  datapolicyEENGLink: PropTypes.string,
  datapolicyHANSGLink: PropTypes.string,
  datapolicyNNNGLink: PropTypes.string,
  datapolicyEDILabel: PropTypes.string,
  datapolicyAVALabel: PropTypes.string,
  datapolicySHNGLabel: PropTypes.string,
  datapolicyBAGLabel: PropTypes.string,
  datapolicyEENGLabel: PropTypes.string,
  datapolicyHANSGLabel: PropTypes.string,
  datapolicyNNNGLabel: PropTypes.string,
};

DebForm.defaultProps = {};

MapTo(['e-fix/components/content/datenerfassungsblatt'])(DebForm);
