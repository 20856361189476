import { NE_ERROR } from 'components/NEForm/Utils/NEError';
import React from 'react';
import {
  always,
  isRegisteringInstallerEnergyGuest,
  isRegisteringInstallerGasGuest,
  never,
  onlyAVA,
  onlyBAG,
  onlyGuestNE,
  onlyHilfsbetrieb,
  MainNE,
  VALIDATION_CONDITIONS,
} from 'global/renderingValidationConstants';

export const NE_FIRST_SECTION_INPUTS = [
  {
    id: 'companyNames',
    title: 'Firmendaten',
    whenShouldSectionRender: always,
    infoText:
      'Hier können Sie Ihre Eintragung ins Installateurverzeichnis beantragen.\n\nBeim Verlassen der Seite werden Ihre Daten nicht gespeichert. Halten Sie bitte alle notwendigen Dokumente für Ihre Eintragung bereit (z.B. Handwerkskarte, Gewerbeanmeldung, Qualifikationsnachweise zur Fachkraft).',
    fields: [
      {
        name: 'companyName1',
        fieldName: 'Firmenname',
        optional: never,
        whenShouldFieldRender: always,
      },
      {
        name: 'companyName2',
        fieldName: 'Firmenname (weitere Angaben)',
        whenShouldFieldRender: always,
      },
      {
        name: 'companyName3',
        fieldName: 'Firmenname (weitere Angaben)',
        whenShouldFieldRender: always,
      },
      {
        name: 'companyOwner',
        fieldName: 'Firmeninhaber',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: onlyBAG,
        },
        whenShouldFieldRender: onlyBAG,
        infoText: 'Bitte in diesem Feld alle Inhaber/Geschäftsführer/Teilhaber eintragen',
      },
    ],
  },
  {
    id: 'companyStreetData',
    whenShouldSectionRender: always,
    fields: [
      {
        name: 'companyStreet',
        fieldName: 'Straße',
        optional: never,
        whenShouldFieldRender: always,
      },
      {
        name: 'companyHouseNumber',
        fieldName: 'Hausnummer',
        optional: never,
        whenShouldFieldRender: always,
      },
    ],
  },
  {
    id: 'companyCity',
    whenShouldSectionRender: always,
    fields: [
      {
        name: 'companyPostal',
        fieldName: 'PLZ',
        optional: never,
        whenShouldFieldRender: always,
      },
      {
        name: 'companyCity',
        fieldName: 'Ort',
        optional: never,
        whenShouldFieldRender: always,
      },
      {
        name: 'companyRegion',
        fieldName: 'Ortsteil',
        whenShouldFieldRender: always,
      },
    ],
  },
  {
    id: 'avaExtraInfo',
    subtitle: 'Zuweisung Auftragsort',
    tooltip: (
      <div className="deb-form__ava-tooltip-table">
        <div>
          Diese Angabe benötigen wir für unsere interne Zuordnung. Sofern Sie die Anmeldung von
          mehreren Installationsanlagen in unserem Gebiet planen, geben Sie die PLZ eines beliebigen
          Auftragsortes an. Ihre Gasteintragung gilt für das gesamte Gebiet der Avacon Netz GmbH. In
          der Tabelle einige Beispiele
        </div>
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th>Standort</th>
              <th>PLZ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Burgwedel</td>
              <td>30938</td>
            </tr>
            <tr>
              <td>Gardelegen</td>
              <td>39638</td>
            </tr>
            <tr>
              <td>Gehrden</td>
              <td>30989</td>
            </tr>
            <tr>
              <td>Lüneburg</td>
              <td>21337</td>
            </tr>
            <tr>
              <td>Salzwedel</td>
              <td>29410</td>
            </tr>
            <tr>
              <td>Sarstedt</td>
              <td>31157</td>
            </tr>
            <tr>
              <td>Schöningen</td>
              <td>38364</td>
            </tr>
            <tr>
              <td>Syke</td>
              <td>28857</td>
            </tr>
            <tr>
              <td>Oschersleben</td>
              <td>39387</td>
            </tr>
            <tr>
              <td>WEVG / Salzgitter</td>
              <td>38226</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
    whenShouldSectionRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyGuestNE, onlyAVA],
    },
    fields: [
      {
        name: 'avaGuestPostal',
        fieldName: 'PLZ Auftragsort',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [onlyAVA, onlyGuestNE],
          },
        },
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [onlyGuestNE, onlyAVA],
        },
      },
    ],
  },
  {
    id: 'companyContacts',
    subtitle: 'Kontaktdaten',
    whenShouldSectionRender: always,
    fields: [
      {
        name: 'mobile',
        fieldName: 'Mobil',
        whenShouldFieldRender: always,

        // infoText: 'Entweder Mobil und/oder Telefonnummer angeben',
      },
    ],
  },
  {
    id: 'companyContacts2',
    whenShouldSectionRender: always,
    fields: [
      { name: 'phone', fieldName: 'Telefon', optional: never, whenShouldFieldRender: always },
      {
        name: 'email',
        fieldName: 'E-Mail-Adresse',
        readOnly: true,
        infoText: '(Entspricht der im ersten Schritt von Ihnen eingetragenen Adresse)',
        optional: never,
        whenShouldFieldRender: always,
      },
    ],
  },
  {
    id: 'craftChamberData',
    subtitle: 'Eintragung bei der Handwerkskammer',
    tooltip: 'Handwerkskarte',
    whenShouldSectionRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [
        onlyBAG,
        {
          type: VALIDATION_CONDITIONS.NOT,
          condition: onlyHilfsbetrieb,
        },
        MainNE,
      ],
    },
    fields: [
      {
        name: 'craftsChamberArea',
        fieldName: 'Ausgestellt von',
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [
            onlyBAG,
            {
              type: VALIDATION_CONDITIONS.NOT,
              condition: onlyHilfsbetrieb,
            },
            MainNE,
          ],
        },
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [
              onlyBAG,
              {
                type: VALIDATION_CONDITIONS.NOT,
                condition: onlyHilfsbetrieb,
              },
              MainNE,
            ],
          },
        },
      },
      {
        name: 'craftsChamberNumber',
        fieldName: 'Betriebsnummer',
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [
            onlyBAG,
            {
              type: VALIDATION_CONDITIONS.NOT,
              condition: onlyHilfsbetrieb,
            },
            MainNE,
          ],
        },
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [
              onlyBAG,
              {
                type: VALIDATION_CONDITIONS.NOT,
                condition: onlyHilfsbetrieb,
              },
              MainNE,
            ],
          },
        },
      },
      {
        name: 'craftsChamberDate',
        fieldName: 'Ausstellungsdatum',
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [
            onlyBAG,
            {
              type: VALIDATION_CONDITIONS.NOT,
              condition: onlyHilfsbetrieb,
            },
            MainNE,
          ],
        },
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [
              onlyBAG,
              {
                type: VALIDATION_CONDITIONS.NOT,
                condition: onlyHilfsbetrieb,
              },
              MainNE,
            ],
          },
        },
      },
    ],
  },
  {
    id: 'businessRegistrationData',
    subtitle: 'Gewerbean-/ummeldung',
    tooltip: 'von der zuständigen Gemeinde/Stadt',
    whenShouldSectionRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyBAG, MainNE],
    },
    fields: [
      {
        name: 'businessRegistrationLocation',
        fieldName: 'Ort',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [
              onlyBAG,
              {
                type: VALIDATION_CONDITIONS.NOT,
                condition: onlyHilfsbetrieb,
              },
              MainNE,
            ],
          },
        },
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [onlyBAG, MainNE],
        },
      },
      {
        name: 'businessRegistrationDate',
        fieldName: 'Ausstellungsdatum',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: {
            type: VALIDATION_CONDITIONS.AND,
            conditions: [
              onlyBAG,
              {
                type: VALIDATION_CONDITIONS.NOT,
                condition: onlyHilfsbetrieb,
              },
              MainNE,
            ],
          },
        },
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [onlyBAG, MainNE],
        },
      },
    ],
  },
  {
    id: 'commercialRegistrationData',
    subtitle: 'Handelsregisterauszug',
    tooltip:
      'mit Nennung der aktuellen Inhaber/Geschäftsführer (bei Firmierung als „e. K.“, „GmbH“, „GmbH & Co. KG“; „AG“, etc.)',
    whenShouldSectionRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyBAG, MainNE],
    },

    fields: [
      {
        name: 'commercialRegisterNumber',
        fieldName: 'HRA/-B-Nummer und Amtsgericht',
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [onlyBAG, MainNE],
        },
        optional: always,
      },
      {
        name: 'commercialRegisterDate',
        fieldName: 'Auszug vom',
        whenShouldFieldRender: {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [onlyBAG, MainNE],
        },
        optional: always,
      },
    ],
  },
  {
    id: 'gridProviderTitle',
    title: 'Gasteintragung',
    infoText:
      'Die Eintragungsdaten zu Firma und Fachkraft müssen den Daten auf Ihrem Installateurausweis entsprechen. Bitte aktualisieren Sie den Ausweis vor der Eintragung, falls erforderlich. Abweichende Daten können nicht eingetragen werden.',
    fields: [],
    whenShouldSectionRender: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [isRegisteringInstallerEnergyGuest, isRegisteringInstallerGasGuest],
    },
  },
  {
    id: 'gridEnergyProvider',
    subtitle: 'Eintragung bei ortsansässigem Netzbetreiber Strom',
    whenShouldSectionRender: isRegisteringInstallerEnergyGuest,
    fields: [
      {
        name: 'gridProviderEnergy',
        fieldName: 'Netzbetreiber',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: isRegisteringInstallerEnergyGuest,
        },
        whenShouldFieldRender: isRegisteringInstallerEnergyGuest,
      },
      {
        name: 'foreignCompanyIdEnergy',
        fieldName: 'Ausweis-Nummer',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: isRegisteringInstallerEnergyGuest,
        },
        whenShouldFieldRender: isRegisteringInstallerEnergyGuest,
      },
    ],
  },
  {
    id: 'gridGasProvider',
    subtitle: 'Eintragung bei ortsansässigem Netzbetreiber Gas',
    whenShouldSectionRender: isRegisteringInstallerGasGuest,

    fields: [
      {
        name: 'gridProviderGas',
        fieldName: 'Netzbetreiber',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: isRegisteringInstallerGasGuest,
        },

        whenShouldFieldRender: isRegisteringInstallerGasGuest,
      },
      {
        name: 'foreignCompanyIdGas',
        fieldName: 'Ausweis-Nummer',
        optional: {
          type: VALIDATION_CONDITIONS.NOT,
          condition: isRegisteringInstallerGasGuest,
        },

        whenShouldFieldRender: isRegisteringInstallerGasGuest,
      },
    ],
  },
];

export const checkErrors = (values, branchSelectionValues, formErrors) => {
  const errors = [];

  if (Object.keys(formErrors).length !== 0) {
    errors.push(NE_ERROR.FORM_FIELD);
  }

  if (values.experts.length === 0) {
    errors.push(NE_ERROR.MIN_EXPERT);
  }

  if (branchSelectionValues.isEnergyActive && branchSelectionValues.isGasActive) {
    const hasEnergyExpert = values.experts.some((expert) => expert.isExpertEnergyActive);
    const hasGasExpert = values.experts.some((expert) => expert.isExpertGasActive);
    if (!hasEnergyExpert || !hasGasExpert) {
      errors.push(NE_ERROR.MIN_BRANCH_EXPERT);
    }
  }

  return errors;
};
