/* eslint-disable react/prop-types */
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import React, { useEffect, useRef, useContext } from 'react';
import { withRouter } from 'react-router';
import { GET_EVENTS_OVERVIEW_DATA_QUERY, USER_STATUS_QUERY } from '../../../Queries/Queries';
import isNewEntry from '../../OverviewLists/utils/isNewEntry';
import EventMetaData from '../EventMetaData/EventMetaData';
import { REMOVE_NEW_TAG_FROM_EVENT_MUTATION } from '../../../Queries/Mutations';
import { ParticipantsListContext } from '../../../context/ParticipantsList';
import { ActionButton } from '../../ActionButton/ActionButton';
import './EventCard.scss';
import { EventIdContext } from '../../../context/EventIdContext';

function EventCard({ data: event, history, isPastEvent }) {
  const {
    data: userStatusData,
    loading: userStatusLoading,
    error: userStatusError,
  } = useQuery(USER_STATUS_QUERY);

  const userStatus = userStatusData && userStatusData.userStatus;

  const { id: eventId } = event;
  const [removeNewTag] = useMutation(REMOVE_NEW_TAG_FROM_EVENT_MUTATION, {
    refetchQueries: () => [{ query: GET_EVENTS_OVERVIEW_DATA_QUERY }],
    awaitRefetchQueries: true,
    variables: {
      eventId,
    },
  });
  const { setList } = useContext(ParticipantsListContext);
  const { setId } = useContext(EventIdContext);

  const cardRef = useRef(null);

  const isNew = isNewEntry(event.tags);

  useEffect(() => {
    if (!cardRef.current || !isNew) return;
    // eslint-disable-next-line
  }, []);

  const handleRegistrationClick = () => {
    history.push({
      pathname: event.path,
    });
    setList(false);
  };

  const handleEntryList = () => {
    history.push({
      pathname: event.path,
    });
    setList(true);
    setId(eventId);
  };

  const handleClick = () => {
    handleRegistrationClick();
    removeNewTag();
    setId(eventId);
  };

  const eventCardClasses = classnames('event-card', {
    'event-card--new': isNew,
  });

  if (userStatusLoading) {
    return null;
  } else if (userStatusError) {
    console.error(userStatusError);
    return null;
  }

  return (
    <div ref={cardRef} className={eventCardClasses}>
      <EventMetaData event={event} isOverview={true} />
      <div className="event-card__cta-container">
        {!isPastEvent && <ActionButton title="Anzeigen" onClick={handleClick} inverted />}
        {userStatus.role === 'POWER_USER' && (
          <div className="event-card__participants-list-button-wrapper">
            <ActionButton title="Teilnehmerliste" onClick={handleEntryList} inverted />
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(EventCard);
