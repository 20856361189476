import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import EventParticipantsList from '../../components/Event/EventParticipantsList/EventParticipantsList';
import EventRegisterForm from '../../components/Event/EventRegisterForm/EventRegisterForm';
import { ParticipantsListContext } from '../../context/ParticipantsList';
import { GET_EVENT_BY_ID } from '../../Queries/Queries';
import { EventIdContext } from '../../context/EventIdContext';
import { USER_STATUS_QUERY } from 'Queries/Queries';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

export const EventDetailPage = () => {
  const { list } = useContext(ParticipantsListContext);
  const { id } = useContext(EventIdContext);
  const installer = useCurrentInstaller();
  const { data: userStatusData, loading: loadingStatusData } = useQuery(USER_STATUS_QUERY);
  const isEditor = Utils.isInEditor();

  const {
    loading: eventLoading,
    error,
    data,
    refetch,
  } = useQuery(GET_EVENT_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="EventDetailPage" />;
  }

  let realLoading = true;

  if (!loadingStatusData && userStatusData) {
    if (userStatusData.userStatus.iamType === 'EIAM' || list === true) {
      realLoading = eventLoading;
    } else {
      realLoading = eventLoading || installer.isLoading || !installer.installer;
    }
  }

  if (realLoading) return <LoadingIndicator />;

  if (error) {
    console.error(error);
    return <div>{'(!) ERROR (!)'}</div>;
  }

  const event = data.event;
  return list ? (
    <EventParticipantsList event={event} />
  ) : (
    <EventRegisterForm
      event={event}
      userStatusData={userStatusData}
      installer={installer}
      refetchEvent={refetch}
    />
  );
};

MapTo('e-fix/components/content/event')(withRouter(EventDetailPage));
