import { Page, Utils, withModel } from '@adobe/aem-react-editable-components';
import { StylesProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Apollo } from './components/ApolloComponent/Apollo';
import ArticlesAndEventsContextProvider from './context/ArticlesAndEventsLimit';
import ParticipantsListContextProvider from './context/ParticipantsList';
import ComponentContainer from './Layouts/_shared/ComponentContainer';
import './global/multiThemingStyling/multiClientGlobalVariables.scss';
import EventIdContextProvider from './context/EventIdContext';
import './global/style/BaseTenantStyle.sass';
import { getTenantId } from 'utils/tenantUtil';
import ErrorHandlingContextContextProvider from 'context/ErrorHandling';
import { PublicHeader } from 'components/PublicHeader/PublicHeader';
import { PublicFooter } from 'components/PublicFooter/PublicFooter';
class App extends Page {
  constructor(props) {
    super(props);

    this.isEditor = Utils.isInEditor();
    this.regions = ['header', 'footer'];
  }

  get regionsData() {
    const regionsData = {};
    this.regions.forEach((region) => {
      regionsData[region] = this.parseRegionsData(region);
    });

    return regionsData;
  }

  parseRegionsData(region) {
    const newProps = _.cloneDeep(this.props);
    const model = Object.entries(newProps.cqChildren)[0][1];
    for (const [key] of Object.entries(model[':items'].root[':items'])) {
      if (key !== region) delete model[':items'].root[':items'][key];
    }
    model[':items'].root[':itemsOrder'] = [region];

    if (!model[':items'].root[':items'][region]) return null;

    return Utils.modelToProps(model);
  }

  render() {
    const { header, footer } = this.regionsData;
    const tenantId = getTenantId();

    return (
      <StylesProvider injectFirst>
        <div className={`App ${tenantId}`}>
          <div className="base-tenant-style">
            <Apollo isPublic={this.props.isPublic}>
              <ErrorHandlingContextContextProvider>
                <ParticipantsListContextProvider>
                  <EventIdContextProvider>
                    <ArticlesAndEventsContextProvider>
                      <div
                        style={{
                          display: 'flex',
                          minHeight: '100vh',
                          flexDirection: 'column',
                        }}>
                        <Helmet>
                          <meta charSet="utf-8" />
                          <title>e-fix</title>
                          <link
                            rel="shortcut icon"
                            href={`${process.env.PUBLIC_URL}/efix_logo.svg`}
                          />
                          <link
                            rel="apple-touch-icon"
                            sizes="180x180"
                            href={`${process.env.PUBLIC_URL}/efix_logo.svg`}
                          />
                          <link rel="manifest" href="./manifest.json" />
                        </Helmet>
                        {header && this.props.isPublic ? (
                          <PublicHeader {...header} />
                        ) : (
                          <ComponentContainer {...header} />
                        )}

                        <div style={{ flex: 1 }}>
                          {this.childComponents}
                          {this.childPages}
                        </div>
                        {footer && this.props.isPublic ? (
                          <PublicFooter {...footer} />
                        ) : (
                          <ComponentContainer {...footer} />
                        )}
                      </div>
                    </ArticlesAndEventsContextProvider>
                  </EventIdContextProvider>
                </ParticipantsListContextProvider>
              </ErrorHandlingContextContextProvider>
            </Apollo>
          </div>
        </div>
      </StylesProvider>
    );
  }
}

export default withModel(App);
